import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext } from "react";
import './SCCard.css';
import { useHistory } from 'react-router-dom';
import appService from '../Images/App-Services.svg';
import functionApp from '../Images/Function-Apps.svg';
import cdnService from '../Images/CDN-Profiles.svg';
import library from '../Images/Activity-Log.svg';
import aksApp from '../Images/azure-aks.svg';
import aksCron from '../Images/cronjob-icon.svg';
import azure from '../Images/azure.svg';
import bronze from '../Images/badge-bronze.svg';
import gold from '../Images/badge-gold.svg';
import silver from '../Images/badge-silver.svg';
import thirtyTwoBit from '../Images/32-bit.svg';
import sixtyFourBit from '../Images/64-bit.svg';
import Tooltip from '@mui/material/Tooltip';
import _ from "lodash";
import * as consts from '../consts';
//https://blogs.vmware.com/management/2018/08/whats-new-vrealize-automation-7-5.html
//https://www.iconpacks.net/free-icon/badge-1360.html
const SCCard = ({ item, theme }) => {
    const { topScore, handleTeamNameById, handleTeamLeaderNameById } = useContext(DataContext);



    useEffect(() => {

        //שינוי צבעים - אם יש מטה דטה  אז ירוק אם חסר צוות \ רצ \ מנהל \ TIER \ תיאור = > אדום


        /*if (item?.serviceMetadata?.newRelicAppName
            && item?.serviceMetadata?.newRelicAppName !== "") {
            if (!_.find(item.serviceMetadata?.tags, { key: 'OpenAI' })) {
                item.serviceMetadata?.tags?.push({
                    key: 'OpenAI',
                    value: 'OpenAi'
                })
            }
        }*/

        let valid = true;

        if (item && item?.serviceMetadata) {

            valid = item.serviceMetadata.userManagerId ? true : false
            valid = item.serviceMetadata.tier ? true : false
            valid = item.serviceMetadata.team ? true : false
            valid = item.serviceMetadata.manager ? true : false
            valid = item.serviceMetadata.description ? true : false

        }
        else {
            valid = false;
        }

        item.metaDataValidation = valid;

    }, [item])


    const getMedalType = (item) => {
        if (topScore && topScore.length > 0) {
            var result = _.filter(topScore, { items: [{ id: item.id }] });

            if (result && result.length > 0) {
                return result[0].medal
            }
        }
    }

    return (

        <div className="container-services-card-item">
            <div className="header-title-service-card">
                <div>

                    <div className={'container-card-first-line ' + (item.metaDataValidation ? 'card-item-valid'
                        : !item.metaDataValidation ? 'card-item-invalid' : 'card-item-not-set')}>
                        <div className="card-first-line-icon">
                            {
                                item.serviceType.toLowerCase() === 'appservice' ?
                                    (
                                        <img src={appService} alt="icon" className="card-type-icon" />
                                    ) :
                                    item.serviceType.toLowerCase() === 'functionapp' ?
                                        (
                                            <img src={functionApp} alt="icon" className="card-type-icon" />
                                        ) :
                                        item.serviceType.toLowerCase() === 'library' ?
                                            (
                                                <img src={library} alt="icon" className="card-type-icon-small" />
                                            ) :
                                            item.serviceType.toLowerCase() === 'jslibrary' ?
                                                (
                                                    <img src={cdnService} alt="icon" className="card-type-icon-small" />
                                                ) :
                                                item.serviceType.toLowerCase() === 'aksapp' ?
                                                    (
                                                        <img src={aksApp} alt="icon" className="card-type-icon-small" />
                                                    ) :
                                                    item.serviceType.toLowerCase() === 'akscronjob' ?
                                                        (
                                                            <img src={aksCron} alt="icon" className="card-type-icon-small" />
                                                        ) :
                                                        (
                                                            <img src={azure} alt="icon" className="card-type-icon-small" />
                                                        )
                            }
                        </div>
                        <div className="card-first-line-title">
                            <Tooltip title={item.name}>
                                <span>{item.name}</span>
                            </Tooltip>
                        </div>
                        <div className="card-first-line-score">
                            {
                                item.serviceScore && item.serviceScore.totalScore ?
                                    (
                                        item.serviceScore.totalScore
                                    ) :
                                    (
                                        <span></span>
                                    )
                            }

                        </div>
                    </div>

                </div>
            </div>
            <div className="header-description">
                {
                    item.serviceMetadata && item.serviceMetadata.description ?
                        (
                            <Tooltip title={item.serviceMetadata.description}>
                                <div className="card-item-text description">
                                    <span>Description: {item.serviceMetadata.description.substring(0, 40)}...</span>
                                </div>
                            </Tooltip>
                        ) :
                        (
                            <span className="card-item-text description">{consts.DESC_DEFAULT}</span>
                        )
                }
                <div className="card-item-text team">Team: {handleTeamNameById(item?.serviceMetadata?.teamId)?.team}</div>
                <div className="card-item-text team">Team Leader: {handleTeamLeaderNameById(item?.serviceMetadata?.userManagerId)}</div>
                <div className="card-item-text team">Tier: {item?.serviceMetadata?.tier}</div>

            </div>

            <div className="tags-container">

                {
                    item.serviceMetadata && item.serviceMetadata.tags ?
                        (
                            item.serviceMetadata.tags.map((tag) => (
                                <span key={Math.random()} className="card-item-tag">{tag.key}:{tag.value}</span>
                            ))
                        ) :
                        (
                            <span></span>
                        )

                }

            </div>
            <div className="card-list-footer">
                <div className="card-list-bit" >
                    {
                        item.use32BitWorkerProcess === "True" ?
                            (
                                <img src={thirtyTwoBit} alt="icon" className="medal-type-icon" />
                            ) : item.use32BitWorkerProcess === "False" ?
                            (
                                <img src={sixtyFourBit} alt="icon" className="medal-type-icon" />
                            ):
                            (
                                <span></span>
                            )
                    }
                </div>
                <div className="card-list-medal" >
                    {
                        getMedalType(item) === 'silver' ?
                            (
                                <img src={silver} alt="icon" className="medal-type-icon" />
                            ) :
                            getMedalType(item) === 'gold' ?
                                (
                                    <img src={gold} alt="icon" className="medal-type-icon" />
                                ) :
                                getMedalType(item) === 'bronze' ?
                                    (
                                        <img src={bronze} alt="icon" className="medal-type-icon" />
                                    ) :
                                    (
                                        <span></span>
                                    )
                    }
                </div>

            </div>
        </div>
    )
}

export default SCCard
