import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzerHistory.css';
import _ from "lodash";
import { Autocomplete, Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { DateTime, DateTime as luxon } from "luxon";
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import SCDatePicker from '../SCDatePicker';
import SCButton from '../SCButton';
import SCTable from '../SCTable';
import ServiceAnalyzerTable from './ServiceAnalyzerTable';
import { toast } from 'react-toastify';
import SCContainer from '../SCContainer';
import { useHistory } from 'react-router-dom';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import ServiceAnalyzerSearch from './ServiceAnalyzerSearch';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ServiceAnalyzerSearchDateAndScan from './ServiceAnalyzerSearchDateAndScan';
import ServiceAnalyzerGroup from './ServiceAnalyzerGroup';
import ServiceAnalyzerReportInfraModal from './ServiceAnalyzerReportInfraModal';
import * as consts from '../../consts';
import ServiceAnalyzerReportModal from './ServiceAnalyzerReportModal';









const ServiceAnalyzerHistory = ({ data }) => {

    const { servicesCached } = useContext(DataContext);
    const [showAnalyzerInfraReport, setShowAnalyzerInfraReport] = useState(false)
    const { GetServiceNameById, LuxonDateFormatString, report } = useContext(DataContextAnalyzer);
    const [showAnalyzerReport, setShowAnalyzerReport] = useState(false)
    const [infraInsightsExist, setInfraInsightsExist] = useState([])
    const [healthInsightsExist, setHealthInsightsExist] = useState([])
    const [selectedServiceName, setSelectedServiceName] = useState([])
    const [selectedServiceID, setSelectedServiceID] = useState([])
    const [isDone, setIsDone] = useState({})
    const [reportForService, setReportForService] = useState([])
    const [insightsData, setInsightsData] = useState([])
    const [servicesAndStatus, setServicesAndStatus] = ([])
    const [historyData, setHistoryDate] = useState([])

    useEffect(()=>{
        let orderedData = _.orderBy(data, i => i.modifiedAt, 'desc');
        setHistoryDate(orderedData)
    },[data])


    const handleGetReport = async (requestId, serviceId, serviceName) => {
        let reportDetails = await report(requestId, serviceId, false);
      //  resultsArr = _.orderBy(reportDetails.data.data.insights, i => i.grantOn, 'desc');
        setInsightsData(reportDetails.data.data.insights)
        //servicesAndStatus //[]
        let item = { id: serviceId, parentId: null, report: reportDetails.data.data.insights, incident: reportDetails.data.data.incident, events: reportDetails.data.data.events };
        setReportForService([item])
        //isDone =>  isDone.status === "Done" 
        setIsDone({status:'Done'})//taltal
        setSelectedServiceID(serviceId)
        setSelectedServiceName(serviceName)
        setShowAnalyzerReport(true)
      


    }

    const handleGetReportInfra = async (requestId, serviceId, serviceName) => {
        let reportDetails = await report(requestId, serviceId);

        let infraInsightsExist = [];
        let healthInsightsExist = [];


        let item = { id: serviceId, parentId: null, report: reportDetails.data.data.insights, incident: reportDetails.data.data.incident, events: reportDetails.data.data.events };


        // reportDetails.data.data.insights.map((item) => {

        if (infraInsightsExist === null || infraInsightsExist?.length === 0) {
            infraInsightsExist = item.report.filter(r => r.module.toString().toLowerCase() === "resourcechanges" && r.code.toLowerCase() === consts.INFRA_CHANGES)
        }

        if (healthInsightsExist === null || healthInsightsExist?.length === 0) {
            healthInsightsExist = item.report.filter(r => r.code.toString().toLowerCase() === "impact")
        }

        // })

      

        setInfraInsightsExist(infraInsightsExist)
        setHealthInsightsExist(healthInsightsExist)
        setSelectedServiceName("Infra")
        setShowAnalyzerInfraReport(true)



    }


    return (
        <React.Fragment>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>SERVICE NAME</TableCell>
                            <TableCell>SERVICE ID</TableCell>
                            <TableCell>CREATED DATE</TableCell>
                            <TableCell>REPORT</TableCell>
                            <TableCell>REPORT INFRA</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {
                            historyData && historyData.length > 0 ?
                                (
                                    historyData.map((d) => (
                                        <TableRow>
                                            <TableCell>{GetServiceNameById(servicesCached, d.serviceId)}</TableCell>
                                            <TableCell>{d.serviceId}</TableCell>
                                            <TableCell>{LuxonDateFormatString(d.modifiedAt)}</TableCell>
                                            <TableCell ><span className='service-analyzer-history-button' onClick={() => handleGetReport(d.requestId, d.serviceId, GetServiceNameById(servicesCached, d.serviceId))}>View Report</span></TableCell>
                                            <TableCell ><span className='service-analyzer-history-button' onClick={() => handleGetReportInfra(d.requestId, d.serviceId, GetServiceNameById(servicesCached, d.serviceId))}>View Infra</span></TableCell>

                                        </TableRow>
                                    ))
                                ) :
                                (
                                    <TableRow>
                                        <TableCell>There is no service history available for the selected date range.</TableCell>
                                    </TableRow>
                                )

                        }

                    </TableBody>
                </Table>
            </TableContainer>

            <ServiceAnalyzerReportInfraModal show={showAnalyzerInfraReport} serviceName={selectedServiceName}
                setShowAnalyzerInfraReport={setShowAnalyzerInfraReport} modules={{
                    infra: infraInsightsExist,
                    health: healthInsightsExist
                }} />

             
             <ServiceAnalyzerReportModal show={showAnalyzerReport} serviceName={selectedServiceName} serviceId={selectedServiceID}
                setShowAnalyzerReport={setShowAnalyzerReport} data={insightsData} servicesAndStatus={servicesAndStatus}
                reportForService={reportForService} isDone={isDone} />
            


        </React.Fragment>
    )
}

export default ServiceAnalyzerHistory