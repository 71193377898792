
import React, { useEffect, useState, useContext } from 'react'
import './SAAnomalyChart.css';
import _ from "lodash";
import CanvasJSReact from '@canvasjs/react-charts';
import { DateTime, DateTime as luxon } from "luxon";


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;




const SAAnomalyChart = ({ type, data, from, to, ready }) => {

    //https://canvasjs.com/docs/charts/chart-options/height/
    //https://canvasjs.com/docs/charts/chart-options/axisy/strip-trend-lines/
    



    const filterChartData = (data) => {

        const fromDate = DateTime.fromISO(from);
        const toDate = DateTime.fromISO(to);

        let filteredData = null;
        if (data !== undefined) {

            filteredData = data.filter(obj => {
                const timestamp = DateTime.fromISO(obj.timestamp);

                return timestamp >= fromDate && timestamp <= toDate;
            });


        }

        return filteredData;

    }

    const buildEmptyOptions = () => {
        const options = {
            animationEnabled: true,
            title: {
                text: type + " (We didn't find any anomaly for this metric)"
            },
            axisX: {
                valueFormatString: "YYYY-MM-DD HH:mm:ss"
            },
            axisY: {
                title: "Value (ms)",
                /*   prefix: "ms",*/

            },
            height: 260,         
            data: [{
                /*  yValueFormatString: "$#,###",
                  xValueFormatString: "MMMM",*/
                type: "spline",
                dataPoints: []
            }]
        }

        return options
    }


    const buildOptions = (dataPoints, avg) => {
        const options = {
            animationEnabled: true,
            title: {
                text: type
            },
            axisX: {
                valueFormatString: "DD-MM-YY HH:mm",
                labelAngle: 45
                //valueFormatString: "MMM"
            },
            axisY: {
                title: "Value (ms)",
                /* prefix: "$",*/
                stripLines: [
                    {

                        value: avg,
                        color: "orange",
                        label: "Avg: " + (avg.toFixed(2)),
                        labelFontColor: "#a8a8a8",
                    }
                ]
            },
            height: 260,          
            data: [{
                /*  yValueFormatString: "$#,###",
                  xValueFormatString: "MMMM",*/
                type: "spline",
                dataPoints: dataPoints
            }]
        }

        return options
    }

    const generateChartOptions = () => {

        if (data?.status === 'fulfilled') {
            let filtered = filterChartData(data.value?.data?.data)
            
            let count = -1;
            let dataPoints = filtered.map((s) => {
                count++;

                const dateTime = DateTime.fromISO(s.timestamp);
                const year = dateTime.year;
                const month = dateTime.month;
                const day = dateTime.day;
                const hour = dateTime.hour;
                const minute = dateTime.minute;
                const second = dateTime.second;
                return { x: new Date(year, month, day, hour, minute, second), y: s.value, 
                    markerColor: s.srCnnEntireResult.alert === 0 ? 'blue' : 'red' ,indexLabel:  s.srCnnEntireResult.alert === 0 ? '' : 'Anomaly'  }



            })

            let avg = calcAvg(dataPoints);

            let options = buildOptions(dataPoints, avg)
            return options;
        }
    }

    const calcAvg = (dataPoints) => {

        var total = 0;
        for (var i = 0; i < dataPoints.length; i++) {
            total += dataPoints[i].y;
        }
        return total / dataPoints.length;

    }

  

    return (

        <React.Fragment>
            {
                 !ready ?
                 (
                 <div className='sa-anomaly-square'>
                     <div className='sa-anomaly-square-spinner linear-wipe'>Fetching anomaly data for {type}</div>
                 </div>
     
                 ): data !== null && ready ?
                 (
                    
                 <CanvasJSChart options={generateChartOptions()}   />
                 ) :
                 (
                 <CanvasJSChart options={buildEmptyOptions()}  />
                 )
            }
        </React.Fragment>

    )


}



export default SAAnomalyChart







