
import React from "react";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import _ from "lodash";
import './ServiceAnalyzerReportModal.css';
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import ServiceAnalyzerSummary from "./ServiceAnalyzerSummary";
import * as consts from '../../consts';
import DataContextAnalyzer from "../../Context/DataContextAnalyzer";
import { useContext } from "react";
import { toast } from 'react-toastify';
import ServiceAnalyzerRootCause from "./ServiceAnalyzerRootCause";
import { usePDF } from 'react-to-pdf';
import ExportPDFHtml from "./refactor/incident/ExportPDFHtml";




const ServiceAnalyzerReportModal = ({ show, setShowAnalyzerReport, data, serviceName, serviceId, servicesAndStatus, reportForService, isDone }) => {

    const { LuxonDateFormatString, SubstringStringForToolTip } = useContext(DataContextAnalyzer);
    const [value, setValue] = React.useState("0");
    const [groupedData, setGroupedData] = useState({});
    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (show) {
            setValue("0")


        }

    }, [show])

    useEffect(() => {

        const groupedData = _.groupBy(data, item => item.module);

        let types = ['Summary', 'RootCause', 'Configuration', 'Database', 'Deployments', 'Diagnostics', 'Logs', 'Metrics', 'ResourceChanges']
        let dataDictionary = {};

        types.map((t) => {

            if ((t in groupedData) && t !== 'RootCause') {

                dataDictionary[t] = groupedData[t];
            }
            else if (t === 'RootCause') {

                let service = reportForService.filter(r => r.id === serviceId);
                if (service.length > 0 && Object.entries(service).length > 0 && service[0].rootCause !== null) {
                    dataDictionary[t] = service[0].rootCause;
                }
                else {
                    dataDictionary[t] = [];
                }
            }
            else {
                dataDictionary[t] = [];
            }


        })



        if (dataDictionary['ResourceChanges'] && dataDictionary['ResourceChanges'].length > 0) {
            dataDictionary['ResourceChanges'] = dataDictionary['ResourceChanges'].filter(x => x.code.toLowerCase() !== consts.INFRA_CHANGES && x.code.toLowerCase() !== consts.HEALTH_CHANGES);
        }




        setGroupedData(dataDictionary)

    }, [data])




    const ParseDictionary = (dic) => {
        var htmlItem = [];
        for (const [key, value] of Object.entries(dic)) {
            if (Object.entries(value).length > 0) {

                Object.entries(value).forEach(([k, v]) => {


                    htmlItem.push(<div><span>{k}:</span>&nbsp;<span>{v}</span></div>)

                })
            }
        }
        return htmlItem;
    }

    const RenderLogs = ({ code, value, elements }) => {

        switch (code.toString().toLowerCase()) {

            case "error_new_ratio": {


                if (!value.matchedCondition) {
                    return (
                        <React.Fragment></React.Fragment>
                    )
                }

                if (!value.matchedCondition.comparedSamples) {
                    return (
                        <React.Fragment></React.Fragment>
                    )
                }

                if (!value.matchedCondition.condition) {
                    return (
                        <React.Fragment></React.Fragment>
                    )
                }

                let sources = [];
                let all = [];
                if (value.data?.errorLogs) {
                    sources = value.data?.errorLogs.filter(item => item.source);

                    all = value.data?.errorLogs.filter(item => !item.source);

                }

                return (
                    <React.Fragment>
                        <div>&nbsp;</div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={6}>

                                            <ul className="data-by-code-ul-summary-log">

                                                <li className="data-by-code-li-none  data-by-code-text-center">
                                                    <span>Matched Condition (Current):</span><span className="data-by-code-value">{value.matchedCondition.comparedSamples.current}</span>
                                                    <span className="data-by-code-pipe">|</span>
                                                    <span className="data-by-code-summary-log">Matched Condition (Other):</span><span className="data-by-code-value">{value.matchedCondition.comparedSamples.other}</span>
                                                    {
                                                        value.matchedCondition.condition ?
                                                            (
                                                                <React.Fragment>
                                                                    <span className="data-by-code-pipe">|</span>
                                                                    <span className="data-by-code-summary-log">Compare Operator:</span><span className="data-by-code-value">{value.matchedCondition.condition.compareOperator}</span>
                                                                    <span className="data-by-code-pipe">|</span>
                                                                    <span className="data-by-code-summary-log">Selector:</span><span className="data-by-code-value">{value.matchedCondition.condition.selector}</span>
                                                                    <span className="data-by-code-pipe">|</span>
                                                                    <span className="data-by-code-summary-log">Value:</span><span className="data-by-code-value">{value.matchedCondition.condition.value}</span>
                                                                </React.Fragment>
                                                            ) :
                                                            (
                                                                <React.Fragment></React.Fragment>
                                                            )
                                                    }
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ width: '14%' }}>Is New</TableCell>
                                        <TableCell sx={{ width: '14%' }}>Logger</TableCell>
                                        <TableCell sx={{ width: '14%' }}>Message</TableCell>
                                        <TableCell sx={{ width: '14%' }}>Error Class</TableCell>
                                        <TableCell sx={{ width: '14%' }}>Error Stack</TableCell>
                                        <TableCell sx={{ width: '14%' }}>Exception</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        all.map((log, index) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + index}>
                                                {
                                                    log.isNew ?
                                                        (
                                                            <TableCell sx={{ width: '14%' }}>True</TableCell>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '14%' }}>False</TableCell>
                                                        )
                                                }

                                                {
                                                    log.logger ?
                                                        (
                                                            <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>{log.logger}</TableCell>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '14%' }}></TableCell>
                                                        )
                                                }

                                                {
                                                    log.message ?
                                                        (
                                                            <Tooltip title={log.message}>
                                                                <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>
                                                                    <div>{SubstringStringForToolTip(log.message)}</div>
                                                                    <div className="report-infra-modal-copy" onClick={() => handleCopy(log.message)}>Copy Text</div>
                                                                </TableCell>
                                                            </Tooltip>

                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '14%' }}></TableCell>
                                                        )
                                                }


                                                {
                                                    log["error.class"] ?
                                                        (
                                                            <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>{log["error.class"]}</TableCell>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '14%' }}></TableCell>
                                                        )
                                                }

                                                {
                                                    log["error.stack"] ?
                                                        (
                                                            <Tooltip title={log["error.stack"]}>
                                                                <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>
                                                                    <div>{SubstringStringForToolTip(log["error.stack"])}</div>
                                                                    <div className="report-infra-modal-copy" onClick={() => handleCopy(log["error.stack"])}>Copy Text</div>
                                                                </TableCell>
                                                            </Tooltip>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '14%' }}></TableCell>
                                                        )
                                                }


                                                {
                                                    log.exception ?
                                                        (
                                                            <Tooltip title={log.exception}>
                                                                <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>
                                                                    <div>{SubstringStringForToolTip(log.exception)}</div>
                                                                    <div className="report-infra-modal-copy" onClick={() => handleCopy(log.exception)}>Copy Text</div>
                                                                </TableCell>
                                                            </Tooltip>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '14%' }}></TableCell>
                                                        )
                                                }

                                            </TableRow>
                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="error-count-diagnostics-logs">Diagnostics Logs</div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '15%' }}>Is New</TableCell>
                                        <TableCell sx={{ width: '25%' }}>Computer</TableCell>
                                        <TableCell sx={{ width: '25%' }}>Source</TableCell>
                                        <TableCell sx={{ width: '25%' }} colspan={3} >Description</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        sources.map((log, index) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + index}>
                                                {
                                                    log.isNew ?
                                                        (
                                                            <TableCell sx={{ width: '15%' }}>True</TableCell>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '15%' }}>False</TableCell>
                                                        )
                                                }

                                                {
                                                    log.computer ?
                                                        (
                                                            <TableCell sx={{ width: '25%' }}>log.Computer</TableCell>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '25%' }}></TableCell>
                                                        )
                                                }

                                                {
                                                    log.source ?
                                                        (
                                                            <TableCell sx={{ width: '25%' }}>{log.source}</TableCell>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '25%' }}></TableCell>
                                                        )
                                                }


                                                {
                                                    log.description ?
                                                        (
                                                            <Tooltip title={log.description}>
                                                                <TableCell sx={{ width: '25%', lineBreak: 'anywhere' }}>
                                                                    <div>{SubstringStringForToolTip(log.description)}</div>
                                                                    <div className="report-infra-modal-copy" onClick={() => handleCopy(log.description)}>Copy Text</div>
                                                                </TableCell>
                                                            </Tooltip>
                                                        ) :
                                                        (
                                                            <TableCell sx={{ width: '25%' }}></TableCell>
                                                        )
                                                }
                                            </TableRow>
                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </React.Fragment>
                )
            }
        }
    }

    const RenderDataByCode = ({ code, value, elements = null }) => {



        switch (code.toString().toLowerCase()) {

            case "cpu":
                {

                    return (
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            <TableCell>Compared Samples - Current</TableCell>
                                            <TableCell>Compared Samples - Other</TableCell>
                                            <TableCell>Condition Operator</TableCell>
                                            <TableCell>Condition Value</TableCell>
                                            <TableCell>Time Period</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{value.code}</TableCell>
                                                    <TableCell >{value.matchedCondition.comparedSamples.current} ({value.data.unitOfMeasure})</TableCell>
                                                    <TableCell >{value.matchedCondition.comparedSamples.other} ({value.data.unitOfMeasure})</TableCell>
                                                    <TableCell >{value.matchedCondition.condition.compareOperator}</TableCell>
                                                    <TableCell >{value.matchedCondition.condition.value}</TableCell>
                                                    <TableCell >{value.matchedCondition.timePeriod}</TableCell>
                                                    <TableCell >{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                                    <TableCell ></TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    )
                }
            case "memory":
                {

                    return (

                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            <TableCell>Compared Samples - Current</TableCell>
                                            <TableCell>Compared Samples - Other</TableCell>
                                            <TableCell>Condition Operator</TableCell>
                                            <TableCell>Condition Value</TableCell>
                                            <TableCell>Time Period</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{value.code}</TableCell>
                                                    <TableCell>{value.matchedCondition.comparedSamples.current} ({value.data.unitOfMeasure})</TableCell>
                                                    <TableCell>{value.matchedCondition.comparedSamples.other} ({value.data.unitOfMeasure})</TableCell>
                                                    <TableCell>{value.matchedCondition.condition.compareOperator}</TableCell>
                                                    <TableCell>{value.matchedCondition.condition.value}</TableCell>
                                                    <TableCell>{value.matchedCondition.timePeriod}</TableCell>
                                                    <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>

                    )
                }
            case "requests":
                {

                    return (

                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            <TableCell>Compared Samples - Current</TableCell>
                                            <TableCell>Compared Samples - Other</TableCell>
                                            <TableCell>Condition Operator</TableCell>
                                            <TableCell>Condition Value</TableCell>
                                            <TableCell>Time Period</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{value.code}</TableCell>
                                                    <TableCell>{value.matchedCondition.comparedSamples.current} ({value.data.unitOfMeasure})</TableCell>
                                                    <TableCell>{value.matchedCondition.comparedSamples.other} ({value.data.unitOfMeasure})</TableCell>
                                                    <TableCell >{value.matchedCondition.condition.compareOperator}</TableCell>
                                                    <TableCell >{value.matchedCondition.condition.value}</TableCell>
                                                    <TableCell >{value.matchedCondition.timePeriod}</TableCell>
                                                    <TableCell >{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>

                    )
                }
            case "response_time":
                {

                    return (

                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            <TableCell>Compared Samples - Current</TableCell>
                                            <TableCell>Compared Samples - Other</TableCell>
                                            <TableCell>Condition Operator</TableCell>
                                            <TableCell>Condition Value</TableCell>
                                            <TableCell>Time Period</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{value.code}</TableCell>
                                                    <TableCell>{value.matchedCondition.comparedSamples.current} ({value.data.unitOfMeasure})</TableCell>
                                                    <TableCell>{value.matchedCondition.comparedSamples.other} ({value.data.unitOfMeasure})</TableCell>
                                                    <TableCell>{value.matchedCondition.condition.compareOperator}</TableCell>
                                                    <TableCell>{value.matchedCondition.condition.value}</TableCell>
                                                    <TableCell>{value.matchedCondition.timePeriod}</TableCell>
                                                    <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>

                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>

                    )
                }
            case "alert": {
                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '12%' }}>Database</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Details</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Rule Name</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Rule Type</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Server</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Sync Type</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Table</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        elements.map((value, innerIndex) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                <TableCell sx={{ width: '12%' }}>{value.data.database}</TableCell>

                                                <Tooltip title={value.data.details}>
                                                    <TableCell sx={{ width: '12%' }}>
                                                        <div>{SubstringStringForToolTip(value.data.details)}</div>
                                                        <div className="report-infra-modal-copy" onClick={() => handleCopy(value.data.details)}>Copy Text</div>
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell sx={{ width: '12%' }}>{value.data.ruleName}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{value.data.ruleType}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{value.data.server}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{value.data.syncType}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{value.data.table}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                            </TableRow>
                                        ))

                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )


            }


            case "diagnostics_cpu_drill_down": {
                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )

            }
            case "diagnostics_memory_drill_down": {
                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_key_vault_app_settings": {

                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_snat_port_exhaustion": {
                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_swap_availability_and_performance": {
                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_tcp_connections_diagnostic": {
                if (value.data && value.data.items) {
                    return (
                        <div>
                            <ul>
                                {
                                    value.data.items.map((item, index) => (
                                        <li className="data-by-code-li" key={index}>
                                            <div>Message: {item.message}</div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_ssl_and_domains": {
                if (value.data && value.data.messages) {
                    return (
                        <div>
                            <ul>
                                {
                                    value.data.messages.map((message, index) => (
                                        <li className="data-by-code-li" key={index}>{message}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_restart": {
                if (value.data && value.data.restartMessages) {
                    return (
                        <div>
                            <div>{value.data.message}</div>
                            <ul>
                                {
                                    value.data.restartMessages.map((item, index) => (
                                        <li className="data-by-code-li" key={index}>
                                            {item}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                else if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_scaling_operations":
                {
                    if (value.data && value.data.messages) {
                        return (
                            <div>
                                <ul>
                                    {
                                        value.data.messages.map((message, index) => (
                                            <li className="data-by-code-li" key={index}>{message}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                    return (
                        <span></span>
                    )
                }
            case "diagnostics_alert":
                {
                    if (value.data && value.data.items) {
                        return (
                            <div>
                                <ul>
                                    {
                                        value.data.items.map((item, index) => (
                                            <li className="data-by-code-li" key={index}>
                                                <div>Name: {item.name}</div>
                                                <div>Description: {item.description}</div>
                                                <div>Status: {item.status}</div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                    return (
                        <span></span>
                    )
                }
            case "diagnostics_event_logs":
                {
                    if (value.data && value.data.items) {
                        return (
                            <div>
                                <ul>
                                    {
                                        value.data.items.map((item, index) => (
                                            <li className="data-by-code-li" key={index}>
                                                <div>Computer: {item.computer}</div>
                                                <div>Date: {item.date}</div>
                                                <div>Description: {item.description}</div>
                                                <div>Level: {item.level}</div>
                                                <div>Event Id: {item.eventId}</div>
                                                <div>Source: {item.source}</div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                    return (
                        <span></span>
                    )
                }


            case "release_definition_history": {

                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Change Type</TableCell>
                                        <TableCell>Changed By</TableCell>
                                        <TableCell>Comment</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        elements.map((value, innerIndex) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                <TableCell >{value.data.changeType}</TableCell>
                                                <TableCell >{value.data.changedBy}</TableCell>
                                                <TableCell >{value.data.comment}</TableCell>
                                                <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                            </TableRow>
                                        ))

                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )
            }
            case "release_history": {
                console.log("release_history", value)
                return (
                    <span></span>
                )
            }

            case "stage_last_modified_on": {


                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Attempt</TableCell>
                                        <TableCell>Created By</TableCell>
                                        <TableCell>Created On</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>URL</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        elements.map((value, innerIndex) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                {/*  <TableCell >{value.data.attempt}</TableCell>
                                                <TableCell >{value.data.createdBy}</TableCell>
                                                <TableCell >{value.data.createdOn}</TableCell>
                                                <TableCell >{value.data.name}</TableCell>
                                                <TableCell >{value.data.status}</TableCell>
                                                <TableCell ><a href={value.data.url} target="_blank" >Click here</a></TableCell>
                                        <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>*/}

                                                <TableCell >{value.data.Attempt}</TableCell>
                                                <TableCell >{value.data.CreatedBy}</TableCell>
                                                <TableCell >{value.data.CreatedOn}</TableCell>
                                                <TableCell >{value.data.Name}</TableCell>
                                                <TableCell >{value.data.Status}</TableCell>
                                                <TableCell ><a href={value.data.Url} target="_blank" >Click here</a></TableCell>
                                                <TableCell>{LuxonDateFormatString(value.OccurredAt)}</TableCell>
                                            </TableRow>
                                        ))

                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )
            }



            case "app_service":
                {


                    return (
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Change Category</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>New Value</TableCell>
                                            <TableCell>Old Value</TableCell>
                                            <TableCell>Property</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{value.data.changeCategory}</TableCell>
                                                    <TableCell >{value.data.description}</TableCell>
                                                    <TableCell >{value.data.newValue}</TableCell>
                                                    <TableCell >{value.data.oldValue}</TableCell>
                                                    <TableCell >{value.data.property}</TableCell>
                                                    <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    )
                }
            case "resource_changes":
                {

                    return (
                        <React.Fragment>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Change Type</TableCell>
                                    <TableCell>Changes Count</TableCell>
                                    <TableCell>Resource Group</TableCell>
                                    <TableCell>Changes</TableCell>
                                    <TableCell>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {

                                    elements.map((value, innerIndex) => (
                                        <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                            <TableCell >{value.data.changeType}</TableCell>
                                            <TableCell >{value.data.changesCount}</TableCell>
                                            <TableCell >{value.data.resourceGroup}</TableCell>
                                            <TableCell >{ParseDictionary(value.data.changes)}</TableCell>
                                            <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                        </TableRow>
                                    ))

                                }

                            </TableBody>
                        </React.Fragment>
                    )
                }

            case "app_configuration_or_key_vault":
                {


                    return (
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ETag</TableCell>
                                            <TableCell>Event Id</TableCell>
                                            <TableCell>Event Type</TableCell>
                                            <TableCell>Key</TableCell>
                                            <TableCell>Label</TableCell>
                                            <TableCell>Provider</TableCell>
                                            <TableCell>Sync Token</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{value.data.etag}</TableCell>
                                                    <TableCell >{value.data.eventId}</TableCell>
                                                    <TableCell >{value.data.eventType}</TableCell>
                                                    <TableCell >{value.data.key}</TableCell>
                                                    <TableCell >{value.data.label}</TableCell>
                                                    <TableCell >{value.data.provider}</TableCell>
                                                    <TableCell >{value.data.syncToken}</TableCell>
                                                    <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment >
                    )

                }


            default:
                {

                    return (
                        <span></span>
                    )
                }

        }
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
        const CopyMsg = () => (
            <div>Text copied to clipboard!</div>
        )
        toast.success(<CopyMsg />)
    }

    const RenderInsightsTable = ({ name, group }) => {


        let elements = group[name];

        if (name.toLowerCase() === "diagnostics") {
            return (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>OccurredAt</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {

                                elements.map((value, innerIndex) => (
                                    <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                        <TableCell >{value.code}</TableCell>
                                        <TableCell>
                                            <RenderDataByCode code={value.code} value={value} />
                                        </TableCell>
                                        <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                    </TableRow>
                                ))

                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        if (name.toLowerCase() === 'metrics' || name.toLowerCase() === 'configuration' || name.toLowerCase() === 'database' || name.toLowerCase() === 'deployments') {

            const groupedData = _.groupBy(elements, 'code');
            return (
                Object.entries(groupedData).map(([key, value]) => (
                    <RenderDataByCode code={key} value={null} elements={value} />
                ))

            )

        }


        if (name.toLowerCase() === 'logs') {
            console.log(name.toLowerCase(), elements)
            return (
                elements.map((value, innerIndex) => (
                    <RenderLogs code={value.code} value={value} elements={elements} />
                ))
            )

        }

        return (
            elements.map((value, innerIndex) => (
                <TableContainer key={innerIndex} component={Paper}>
                    <Table>
                        <RenderDataByCode code={value.code} value={value} elements={elements} />
                    </Table>
                </TableContainer>
            ))

        )
    }


    const RenderTableView = ({ name, group }) => {


        let elements = group[name];


        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>OccurredAt</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {

                            elements.map((value, innerIndex) => (
                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                    <TableCell >{value.code}</TableCell>
                                    <TableCell>
                                        <RenderDataByCode code={value.code} value={value} />
                                    </TableCell>
                                    <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                </TableRow>
                            ))

                        }

                    </TableBody>
                </Table>
            </TableContainer>
        )



    }
    const DisplayTabs = () => {
        if (groupedData) {



            return (
                <div>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value.toString()}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    {Object.entries(groupedData).map(([key, values], index) => (

                                        values.length > 0 || key === "Summary" || Object.entries(values).length > 0 ?
                                            (
                                                <Tab label={key} value={index.toString()} key={`modal--key-name-element` + index} />
                                            ) :
                                            (
                                                <Tab disabled label={key} value={index.toString()} key={`modal--key-name-element` + index} />
                                            )



                                    ))}


                                </TabList>
                            </Box>
                            {
                                Object.entries(groupedData).map(([key, values], innerIndex) => (
                                    <TabPanel value={innerIndex.toString()} key={`panel-element` + innerIndex}>
                                        {
                                            key === "Summary" ?
                                                (
                                                    <ServiceAnalyzerSummary data={groupedData} setValue={setValue} insights={data}
                                                        servicesAndStatus={servicesAndStatus} serviceId={serviceId}
                                                        reportForService={reportForService} isDone={isDone} />
                                                ) : key === "RootCause" ?
                                                    (
                                                        <ServiceAnalyzerRootCause data={values} insights={groupedData} />
                                                    ) :
                                                    (
                                                        /*<RenderTableView name={key} group={groupedData} />*/
                                                        <RenderInsightsTable name={key} group={groupedData} />

                                                    )
                                        }
                                    </TabPanel>
                                ))
                            }

                        </TabContext>
                    </Box>
                </div>
            )
        }
        return (
            <div>NO DATA</div>
        )
    }

    const AnalyzerReportModalClose = () => {

        setShowAnalyzerReport(false)
    }




    return (

        <React.Fragment>
            <Modal
                fullscreen={true}
                show={show}
                onHide={() => AnalyzerReportModalClose()}

                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Insights For <span className="anomaly-alert-servicename">{serviceName}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="notification-modal-container">
                    <div>
                        <div>
                          
                        </div>
                    </div>
                    <div>
                        <DisplayTabs />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => AnalyzerReportModalClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>



    );
};

export default ServiceAnalyzerReportModal;
