
import './UserAutoComplete.css';
import DataContext from '../Context/DataContext';
import { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';



const UserAutoComplete = ({ handleAutoUserClick, inputName, inputValue, customClass = "", readOnly = false, useRef = null }) => {
    const { getTelAvivUsers } = useContext(DataContext);
    const [options, setOptions] = useState([])
    const handleAutoChange = (value) => {

        if (value.length <= 2) {
            setOptions([])
        }
        if (value != '' && value.length > 2) {
            // setLoading(true)
            getTelAvivUsers(value).then((response) => {

                let arr = [];
                response.data.forEach(d => {
                    arr.push({ name: d.displayName, id: d.azureAdObjectId })
                });
                setOptions([...arr])
                //   setLoading(false)

            }, (error) => {
                console.log('Error', error)
                //    setLoading(false)
            })
        }
    }



    return (
        <>
            {
                useRef !== null ?
                    (
                        <Autocomplete
                            id="service-ac"
                              
                            ref={useRef}                        
                            disabled={readOnly}
                            disableClearable={readOnly}
                            options={options?.map((option) => ({ value: option.id, label: option.name }))}
                            getOptionLabel={(option) => option.label}
                            value={inputValue}
                           
                            onChange={(e, value) => handleAutoUserClick(e, value)}
                            renderInput={(params) => <TextField  className='UserAutoComplete MuiFormControl-root'
                                onChange={(event) =>
                                    handleAutoChange(event.target.value)
                                }
                                {...params}  label={inputName} InputLabelProps={{ shrink: true }} />}

                        />
                    ) :
                    (
                        <Autocomplete
                            id="service-ac"       
                              
                            disabled={readOnly}
                            disableClearable={readOnly}
                            options={options?.map((option) => ({ value: option.id, label: option.name }))}
                            getOptionLabel={(option) => option.label}
                            value={inputValue}
                           
                            onChange={(e, value) => handleAutoUserClick(e, value)}
                            renderInput={(params) => <TextField {...params}  className='UserAutoComplete MuiFormControl-root'
                                onChange={(event) =>
                                    handleAutoChange(event.target.value)
                                }
                                {...params}  label={inputName} InputLabelProps={{ shrink: true }}  />}
                        />


                    )
            }

        </>
    )
}

export default UserAutoComplete
