import React, { useEffect, useRef, useState, useContext } from 'react'
import SCTop from "../Components/SCTop";
import './AutoCICD.css';
import SCInputText from "../Components/SCInputText";
import SCButton from "../Components/SCButton";
import SCDropDownList from '../Components/SCDropDownList';
import * as consts from '../consts';
import DataContext from "../Context/DataContext";
import _ from "lodash";
import cicdAxios from '../cicdAxios';
import { toast } from 'react-toastify';
import AlertModal from '../Modals/AlertModal';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SCTable from '../Components/SCTable';
import { useHistory } from 'react-router-dom';
import useRefTeamLeaderCallback from '../Hooks/Ref/useRefTeamLeaderCallback'
import useRefTeamLeaderIdCallback from '../Hooks/Ref/useRefTeamLeaderIdCallback'
import useRefTeamNameCallback from '../Hooks/Ref/useRefTeamNameCallback'
import TextField from '@mui/material/TextField';
import SCTextArea from '../Components/SCTextArea';
import PipelineTreeModal from '../Modals/PipelineTreeModal';
import { Autocomplete } from '@mui/material';
import BackgroundDataContext from '../Context/BackgroundDataContext';
import useAxios from '../Hooks/useAxios';
import useLogin from '../Hooks/useLogin';



const AutoCICD = () => {



  const appNameRef = useRef(null)
  const serviceNameRef = useRef(null)
  const instance = useAxios(process.env.REACT_APP_CICD_BASE_URL);


  const { token, setCurrentPage, setModalAlertShow, sortingAtoZ, listOfDepartments, showLoader, hideLoader,
    setConnection, connection, requestQueue, tabsAutoCICDValue, setTabsAutoCICDValue, getApprovalManagerDropDown, listOfTeams, initTeams, getSelfServiceBranchesForExsitingRepo,
    requestCICDItems, getUserCICDRequests, initDeperatmentManager, initSubDeperatmentManager, initTiers, getSelfServiceRepositories, getSelfServiceEnvironments } = useContext(DataContext);

  const { ddlProjects, setDdlProjects, ddlDBGroup, setDdlDBGroup } = useContext(BackgroundDataContext);


  const [selectedTier, setSelectedTier] = useState('')

  //const [ddlProjects, setDdlProjects] = useState([])
  const [ddlFeeds, setDdlFeeds] = useState([])
  const [selectedProject, setSelectedProject] = useState('')
  const [selectedFeed, setSelectedFeed] = useState('')


  const [ddlAcrDev, setDdlAcrDev] = useState([])//-prod-
  const [ddlAcrProd, setDdlAcrProd] = useState([])//-prod-

  const [repositories, setRepositories] = useState([])//-prod-

  const [options, setOptions] = useState([])

  //const [ddlDBGroup, setDdlDBGroup] = useState([])

  const [selectedACRDev, setSelectedACRDev] = useState('')
  const [selectedACRProd, setSelectedACRProd] = useState('')

  const [selectedDBGroup, setSelectedDBGroup] = useState('')



  const [ddlTeams, setDdlTeams] = useState([]);

  const [serviceDescription, setServiceDescription] = useState('')
  const [ddlTier, setDdlTier] = useState([]);

  const [ddlBranchesDev, setDdlBranchesDev] = useState([])
  const [ddlBranchesProd, setDdlBranchesProd] = useState([])

  const [ddlBranchesDevCached, setDdlBranchesDevCached] = useState([])
  const [ddlBranchesProdCached, setDdlBranchesProdCached] = useState([])

  const [ddlManager, setDdlManager] = useState([])
  const [selectedBranchesProd, setSelectedBranchesProd] = useState('')
  const [selectedBranchesDev, setSelectedBranchesDev] = useState([])

  const [disableRepoName, setDisableRepoName] = useState(false)

  const [selectedManager, setSelectedManager] = useState('');

  const [ddlSolutionType, setDdlSolutionType] = useState([])
  const [ddlFramework, setDdlFramework] = useState([])

  const [selectedSolutionType, setSelectedSolutionType] = useState('');
  const [selectedFramework, setSelectedFramework] = useState('');

  const [selectedTeam, setSelectedTeam] = useState('')

  const [teamLeaderRef] = useRefTeamLeaderCallback();
  const [teamLeaderIdRef] = useRefTeamLeaderIdCallback();
  const [teamNameRef] = useRefTeamNameCallback();

  const [appNameValue, setAppNameValue] = useState('');
  const [serviceNameValue, setServiceNameValue] = useState('');

  const [repoNameValue, setRepoNameValue] = useState('');
  const [repoNameFromAutoComplete, setRepoNameFromAutoComplete] = useState('');

  const [dbNameValue, setDBNameValue] = useState('');

  const [type, setType] = useState(0)


  const [error, setError] = useState(false);
  const [errorClusterProd, setErrorClusterProd] = useState(false)
  const [shake, setShake] = useState(false);
  const pattern = /^[A-Z][A-Za-z]*(?:\.[A-Z][A-Za-z]*)*$/;
  const patternServiceName = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;
  const patternDBName = /^[a-zA-Z0-9]*$/;


  const [regexError, setRegexError] = useState(false);
  const [regexServiceError, setRegexServiceError] = useState(false);
  const [regexDBNameError, setRegexDBNameError] = useState(false);


  const [regexShake, setRegexShake] = useState(false);

  const [text, setText] = useState()

  const [showBuildPipelineTree, setShowBuildPipelineTree] = useState(false);
  const [showReleasePipelineTree, setShowReleasePipelineTree] = useState(false);



  const [selectedBuildFolder, setSelectedBuildFolder] = useState("");
  const [selectedReleaseFolder, setSelectedReleaseFolder] = useState("");

  //.Net 8 Custom
  const [ddlAppConfig, setDdlAppConfig] = useState(false)
  const [selectedAppConfig, setSelectedAppConfig] = useState('')
  const [selectedAppConfigUrl, setSelectedAppConfigUrl] = useState('')
  const [selectedMongo, setSelectedMongo] = useState('')
  const [selectedEF, setSelectedEF] = useState('')

  const [errorAppConfig, setErrorAppConfig] = useState(false)


  const tabListRef = useRef();

  const [ddlDeperatmentManager, setDdlDeperatmentManager] = useState([]);
  const [ddlSubDeperatmentManager, setDdlSubDeperatmentManager] = useState([]);
  const [selectedDeperatmentManager, setSelectedDeperatmentManager] = useState('');
  const [selectedSubDeperatmentManager, setSelectedSubDeperatmentManager] = useState('')

  const waitingValidMessage = "We are handling your request, it will take a while, you will get a notification as soon as the process is ready or if there is some conflict";
  const waitingInValidMessage = "Request with the same parameters is in process";

  const columnsItems = [

    { display: true, name: "App Name", key: "appName", width: "7%" },
    { display: true, name: "Repo Name", key: "repoName", width: "7%" },
    { display: true, name: "Build Pipeline Url", key: "buildPipelineUrl", width: "7%" },
    { display: true, name: "Release Pipeline Url", key: "releasePipelineUrl", width: "7%" },
    { display: true, name: "Repo Url", key: "repoUrl", width: "7%" },
    { display: true, name: "DevOps Project", key: "azureDevOpsProject", width: "7%" },
    { display: true, name: "DB Creation Status", key: "dataBaseCreationStatus", width: "7%" },
    { display: true, name: "DB KeyVault Key", key: "dataBaseKeyVaultKey", width: "7%" },
    { display: true, name: "DB KeyVault Name", key: "dataBaseKeyVaultName", width: "7%" },
    { display: true, name: "Manager", key: "approvalManagerEmail", width: "7%" },
    { display: true, name: "Approval Status", key: "approvalStatus", width: "7%" },
    { display: true, name: "Ticket Number", key: "approvalTicketNumber", width: "7%" },
    { display: true, name: "Created On", key: "createdOn", width: "7%" },
    { display: true, name: "Process Status", key: "processStatus", width: "7%" },
    { display: false, name: "partitionKey", key: "partitionKey", width: "0%" },
    { display: false, name: "readByServiceCatalog", key: "readByServiceCatalog", width: "0%" },
    { display: false, name: "failureReason", key: "failureReason", width: "0%" },

  ]


  const displayErrorToast = (message) => {
    const ErrorMsg = () => (
      <div>{message}</div>
    )
    toast.error(<ErrorMsg />)
  }

  useEffect(() => {

    setDdlAppConfig([{ name: 'Yes', value: true }, { name: 'No', value: false }])

  }, [])
  useEffect(() => {
    /*
     if(ddlProjects.length === 0 || ddlDBGroup.length === 0){
      showLoader();
      
     }
     else{
       hideLoader();
 
     }*/

    if (ddlProjects.length === 0) {
      displayErrorToast('Your user account is not related to any project, please contact to infra team')
      console.log("***PROJECT***", ddlProjects)
    }
    if (ddlDBGroup.length === 0) {
      displayErrorToast('Your user account is not related to any DB group, please contact to infra team')
      console.log("***DB GROUP***", ddlDBGroup)
    }


  }, [ddlProjects, ddlDBGroup])

  useEffect(() => {


    setCurrentPage('auto-cicd')
    var solutionTypes = [];

    solutionTypes.push({ name: 'WebApi', value: 'WebApi' }, { name: 'QueueProcessor', value: 'QueueProcessor' }, { name: 'CronWorker', value: 'CronWorker' })
    setDdlSolutionType(solutionTypes)
    setDdlTier(initTiers())

    var frameworkTypes = [];

    frameworkTypes.push({ name: 'Dot.Net 6', value: '.Net6' }, { name: 'Dot.Net 8', value: '.Net8' })
    setDdlFramework(frameworkTypes)

    let managersDdl = getApprovalManagerDropDown();
    setDdlManager(sortingAtoZ(managersDdl));

    return () => {
      setTabsAutoCICDValue({ TabValue: '0' })

    };


  }, [])

  useEffect(() => {

    if (listOfDepartments && listOfDepartments.length > 0) {

      setDdlDeperatmentManager(initDeperatmentManager())
      setDdlSubDeperatmentManager(initSubDeperatmentManager())

    }


  }, [listOfDepartments])

  useEffect(() => {
    setDdlTeams(initTeams())
  }, [listOfTeams])


  useEffect(() => {

    if (!connection) {
      return;
    }
    else {
      if (selectedProject) {
        invokeNewRequest();
      }

    }

  }, [connection]);

  //init contollers
  /*useEffect(async () => {
    if (token) {
   
      //managers list
      let managersDdl = getApprovalManagerDropDown();
      setDdlManager(sortingAtoZ(managersDdl));
    }
  }, [token, instance])*/

  useEffect(async () => {


    if (selectedProject) {

      let response = await Promise.allSettled([getSelfServiceRepositories(selectedProject), getSelfServiceEnvironments(selectedProject)])
      if (response[0].status === "fulfilled") {
        setRepositories([])
        var repositories = [];
        response[0].value.data.content.map((a) => {

          repositories.push({
            id: a.id,
            name: a.name
          })
        })
        setRepositories(repositories)
      }

      if (response[1].status === "fulfilled") {
        var resourceDev = [];
        var resourceProd = [];

        response[1].value.data.content.map((a) => {

          if (a.name.toLowerCase().includes("-prod-")) {
            resourceProd.push({
              name: a.displayName,
              value: a.id,
              branches: a.allowedBranches
            })
          }
          if (a.name.toLowerCase().includes("-dev-")) {
            resourceDev.push({
              name: a.displayName,
              value: a.id,
              branches: a.allowedBranches
            })
          }
        })
        setDdlAcrDev(resourceDev)
        setDdlAcrProd(resourceProd)
      }
    }

  }, [selectedProject])

  useEffect(() => {
    if (selectedACRDev) {
      setDdlBranchesDev([])
      let result = ddlAcrDev.filter(a => a.value === selectedACRDev)
      if (result) {
        var resource = [];

        result.map((i) => (
          i.branches?.map((b) => {
            resource.push({
              name: b,
              value: b,
            })
          })
        ))
        setDdlBranchesDev(resource)
        setDdlBranchesDevCached(resource)


      }
    }

  }, [selectedACRDev])

  useEffect(() => {
    if (selectedACRProd) {
      setDdlBranchesProd([])
      let result = ddlAcrProd.filter(a => a.value === selectedACRProd)

      if (result) {
        var resource = [];

        result.map((i) => (
          i.branches?.map((b) => {
            resource.push({
              name: b,
              value: b,
            })
          })
        ))
        setDdlBranchesProd(resource)
        setDdlBranchesProdCached(resource)
      }
    }

  }, [selectedACRProd])

  useEffect(() => {

    if (token && tabsAutoCICDValue.TabValue === '1') {
      getUserCICDRequests();
    }
    if (appNameRef?.current) {
      appNameRef.current.value = appNameValue;
    }
    if (serviceNameRef?.current) {
      serviceNameRef.current.value = serviceNameValue;
    }


  }, [tabsAutoCICDValue])


  const handleChangeDeperatmentManager = (e) => {
    setSelectedDeperatmentManager(e.target.value);
  }
  const handleChangeSubDeperatmentManager = (e) => {
    setSelectedSubDeperatmentManager(e.target.value);
  }


  const buildCommand = () => {


    const dictionary = new Map();


    dictionary.set('--TeamId', selectedTeam);
    dictionary.set('--TeamName', teamNameRef.value);
    dictionary.set('--TeamLeaderId', teamLeaderIdRef.value);
    dictionary.set('--TeamLeaderName', teamLeaderRef.value);
    dictionary.set('--Description', serviceDescription);
    dictionary.set('--Tier', selectedTier);
    dictionary.set('--SubDepartmentManager', selectedSubDeperatmentManager);
    dictionary.set('--DepartmentManager', selectedDeperatmentManager);

    //taltal
    if (selectedMongo === true && selectedFramework === '.Net8') {
      dictionary.set('--UseMongodb', true);
    }
    if (selectedEF === true && selectedFramework === '.Net8') {
      dictionary.set('--UseEf', true);
    }
    if (selectedAppConfig === true && selectedAppConfigUrl !== "" && selectedFramework === '.Net8') {
      dictionary.set('--UseAzureAppConfiguration', true);
      dictionary.set('--AppConfigurationName', selectedAppConfigUrl);

    }


    return dictionary;
  }

  const invokeNewRequest = () => {

    if (disableRepoName) {
      requestQueue.enqueue({ repositoryName: repoNameFromAutoComplete, applicationName: appNameValue });
    }
    else {
      requestQueue.enqueue({ repositoryName: repoNameValue, applicationName: appNameValue });
    }


    let map = buildCommand();
    let commandArguments = Object.fromEntries([...map]);


    const dictionary = new Map();

    if (selectedACRDev) {

      dictionary.set(selectedACRDev, selectedBranchesDev);
    }
    if (selectedACRProd) {
      let arr = [selectedBranchesProd];
      dictionary.set(selectedACRProd, arr);
    }

    let commandAcrAndBranches = Object.fromEntries([...dictionary]);
    instance.post(consts.requests.createCICDAuto, {
      projectName: ddlProjects.filter(d => d.value === selectedProject)[0].name,
      solutionName: appNameValue,
      appName: serviceNameValue,
      repositoryName: disableRepoName ? repoNameFromAutoComplete : repoNameValue,
      dataBaseTeamName: dbNameValue === "" ? null : selectedDBGroup,
      dataBaseName: selectedDBGroup === "" || dbNameValue === "" ? null : dbNameValue,
      acrGuidsAndBranches: commandAcrAndBranches,
      approvalManagerEmail: selectedManager,
      solutionType: selectedSolutionType,
      framework: selectedFramework,
      solutionArguments: commandArguments,
      buildPipelinePath: selectedBuildFolder,
      releasePipelinePath: selectedReleaseFolder,
      repositoryExists: repoNameFromAutoComplete !== "" ? true : false,
      feedName: selectedFeed



    }).then((response) => {

      if (response) {

        setText(waitingValidMessage)
        setType(1)
        setModalAlertShow(true);
        resetFormFields()

      }

    }).catch((error) => {

      let isExist;
      if (disableRepoName) {
        isExist = requestQueue.isItemExist({ repositoryName: repoNameFromAutoComplete, applicationName: appNameValue });
      }
      else {
        isExist = requestQueue.isItemExist({ repositoryName: repoNameValue, applicationName: appNameValue });
      }

      if (isExist) {
        if (disableRepoName) {
          requestQueue.remove({ repositoryName: repoNameFromAutoComplete, applicationName: appNameValue });
        }
        else {
          requestQueue.remove({ repositoryName: repoNameValue, applicationName: appNameValue });
        }
      }


    }).finally(() => { })


  }

  const resetFormFields = () => {

    setServiceNameValue('')
    setAppNameValue('')
    if (appNameRef?.current?.value) {
      appNameRef.current.value = ''
    }
    if (serviceNameRef?.current?.value) {
      serviceNameRef.current.value = ''
    }

    setDBNameValue('')
    setRepoNameValue('')
    setRepoNameFromAutoComplete('')
    setSelectedBranchesProd('')
    setSelectedBranchesDev([])
    setSelectedDBGroup('')
    setDdlBranchesDev([])
    setDdlBranchesProd([])
    setSelectedManager('')
    setSelectedACRDev('')
    setSelectedACRProd('')
    setSelectedProject('')
    setSelectedFeed('')
    setDdlAcrDev([])
    setDdlAcrProd([])
    setRepoNameFromAutoComplete('')
    setSelectedSolutionType('')
    setSelectedFramework('')
    setServiceDescription('')
    setSelectedTeam('')
    setSelectedDeperatmentManager('')
    setSelectedSubDeperatmentManager('')
    setSelectedTier('')

    setSelectedBuildFolder("")
    setSelectedReleaseFolder("")

    if (teamLeaderRef?.current?.value) {
      teamLeaderRef.current.value = ''
    }

    if (teamLeaderIdRef?.current?.value) {
      teamLeaderIdRef.current.value = ''
    }
    if (teamNameRef?.current?.value) {
      teamNameRef.current.value = ''
    }

    setSelectedAppConfig('')
    setSelectedAppConfigUrl('')
    setSelectedMongo('')
    setSelectedEF('')

  }



  const startSignalRConnection = () => {

    if (connection && connection?._connectionState === 'Connected') {
      setConnection(connection)

      invokeNewRequest();
    }
    else {


      const newConnection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_HUB_URL, {
          accessTokenFactory: () => token
        })
        .build();

      setConnection(newConnection);
    }

  }



  /*
    const getCICDAutoProjects = async () => {
  
      try {
        return await cicdAxios.get(consts.requests.getCICDAutoProjects, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
      }
      catch (error) {
  
      }
  
    }
  
    const getCICDDatabaseNames = async () => {
  
      try {
        return await cicdAxios.get(consts.requests.getCICDDatabaseNames, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      }
      catch (error) {
  
      }
  
    }*/



  const handleChangeProject = (e) => {


    setSelectedProject(e.target.value)
    fillFeedDropdown(e.target.value);
    setSelectedACRDev('')
    setSelectedACRProd('')
    setSelectedBranchesProd('')
    setSelectedBranchesDev([])

  }
  const handleChangeFeed = (e) => {
    setSelectedFeed(e.target.value)

  }

  const handleChangeACRDev = (e) => {

    setSelectedACRDev(e.target.value)
    setSelectedBranchesDev([])

  }

  const handleChangeACRProd = (e) => {
    setSelectedACRProd(e.target.value)
    setSelectedBranchesProd('')

  }



  const fillFeedDropdown = (selectedProject) => {


    let url = consts.requests.getCICDAutoFeed.replace('{projectName}', selectedProject);
    instance.get(url).then((response) => {

      var resource = [];
      if (response?.data?.content) {
        response.data.content?.map((feed) => {
          resource.push({
            name: feed.name,
            value: feed.name
          })
        })
      }
      else {
        setDdlFeeds([])
      }

      setDdlFeeds(resource)


    }).catch((error) => {
      setDdlFeeds([])
    })





  }


  const handleChangeBranchesDev = (e) => {
    setSelectedBranchesDev(e.target.value);
  }
  const handleChangeBranchesProd = (e) => {
    setSelectedBranchesProd(e.target.value);
  }

  const handleBlur = (e) => {


    if (pattern.test(e.target.value)) {
      setRegexError(false);
      setRegexShake(false);

    }

  };

  const handleDBNameBlur = (e) => {
    if (patternDBName.test(e.target.value)) {
      setRegexDBNameError(false);
      setRegexShake(false);

    }

  }
  const handleDBNameChange = (e) => {


    if (patternDBName.test(e.target.value)) {
      setDBNameValue(e.target.value)
      setRegexDBNameError(false)

    }
    else {
      setRegexDBNameError(true);
      setRegexShake(true)
      setTimeout(() => {
        setRegexShake(false);
      }, 500);
      return;
    }
  }

  const handleServiceNameBlur = (e) => {
    if (patternServiceName.test(e.target.value)) {
      setRegexServiceError(false);
      setRegexShake(false);

    }

  }


  const handleServiceNameChange = (e) => {
    if (patternServiceName.test(e.target.value)) {
      setServiceNameValue(e.target.value)
      setRegexServiceError(false)

    }
    else {
      setRegexServiceError(true);
      setRegexShake(true)
      setTimeout(() => {
        setRegexShake(false);
      }, 500);
      return;
    }



  }

  const handleAppNameChange = (e) => {
    if (pattern.test(e.target.value)) {
      setAppNameValue(e.target.value)
      setRegexError(false)

    }
    else {
      setRegexError(true);
      setRegexShake(true)
      setTimeout(() => {
        setRegexShake(false);
      }, 500);
      return;
    }
  }
  const handleRepoNameChange = (e) => {
    setRepoNameValue(e.target.value)

  }




  const handleCreateRequest = (e) => {

    e.preventDefault();


    if (regexError || regexServiceError) {
      setRegexShake(true);
      setShake(true)
      setTimeout(() => {
        setRegexShake(false);
        setShake(false)
      }, 500);
      return;
    }

    if ((selectedACRProd.length > 0 && selectedBranchesProd === "") || (selectedACRProd.length === 0 && selectedBranchesProd !== "")) {
      setErrorClusterProd(true);
      setShake(true)
      setTimeout(() => {
        setShake(false);
      }, 500);
      return;
    }
    else {
      setErrorClusterProd(false);
    }


    if (selectedAppConfig === true && selectedAppConfigUrl === "") {
      setErrorAppConfig(true);
      setShake(true)
      setTimeout(() => {
        setShake(false);
      }, 500);
      return;
    }
    else {
      setErrorAppConfig(false);
    }



    if (appNameValue !== "" && serviceNameValue !== "" && (repoNameValue !== "" || repoNameFromAutoComplete !== "") &&
      selectedBuildFolder !== "" && selectedReleaseFolder !== "" &&
      (selectedACRDev.length > 0 && selectedBranchesDev.length > 0) && selectedProject !== "" && selectedManager !== "" && selectedSolutionType !== "" && selectedTier !== ""
      && selectedFeed !== ""
      && serviceDescription !== "" && selectedTeam !== "" && selectedSubDeperatmentManager !== "" && selectedDeperatmentManager !== "" && !teamLeaderRef.value !== ""
      && teamLeaderIdRef.value !== ""
      && selectedFramework !== ""
    ) {
      setError(false)
    }
    else {
      setError(true);
      setShake(true)
      setTimeout(() => {
        setShake(false);
      }, 500);
      return;

    }


    if (token) {
      let isExist;

      if (disableRepoName) {
        isExist = requestQueue.isItemExist({ repositoryName: repoNameFromAutoComplete, applicationName: appNameValue });
      }
      else {
        isExist = requestQueue.isItemExist({ repositoryName: repoNameValue, applicationName: appNameValue });
      }

      if (isExist) {

        setText(waitingInValidMessage)
        setType(3) // duplicate
        setModalAlertShow(true)

      }
      else {
        startSignalRConnection();
      }

    }

  }

  const handleOpenDev = () => {
    buildClusterComponents('dev');
  }
  const handleOpenProd = () => {
    buildClusterComponents('prod');
  }
  const buildClusterComponents = async (type) => {
    if (selectedProject !== "" && repoNameFromAutoComplete !== "" && disableRepoName) {

      if (type === 'dev') {

        var cloneDdlBrancedDev = [...ddlBranchesDev]
        setDdlBranchesDev([])
        let results = await getSelfServiceBranchesForExsitingRepo(selectedProject, repoNameFromAutoComplete);

        results.data.content.map((r) => {

          if (r.name.toLowerCase() === 'dev') {

            let newDdl = cloneDdlBrancedDev.filter((d) => d.name.toLowerCase() === 'dev');
            if (newDdl.length > 0) {

              newDdl[0].name = newDdl[0].name + " (Already Exist)";
              newDdl[0].disable = true;

            }
          }

          if (r.name.toLowerCase() === 'staging') {

            let newDdl = cloneDdlBrancedDev.filter((d) => d.name.toLowerCase() === 'staging');
            if (newDdl.length > 0) {

              newDdl[0].name = newDdl[0].name + " (Already Exist)";
              newDdl[0].disable = true;

            }
          }
          setDdlBranchesDev([...cloneDdlBrancedDev]);
        })
      }
      if (type === 'prod') {

        var cloneDdlBrancedProd = [...ddlBranchesProd]
        setDdlBranchesProd([])
        let results = await getSelfServiceBranchesForExsitingRepo(selectedProject, repoNameFromAutoComplete);


        results.data.content.map((r) => {

          if (r.name.toLowerCase() === 'main') {

            let newDdl = cloneDdlBrancedProd.filter((d) => d.name.toLowerCase() === 'main');
            if (newDdl.length > 0) {

              newDdl[0].name = newDdl[0].name + " (Already Exist)";
              newDdl[0].disable = true;
            }
          }
          if (r.name.toLowerCase() === 'master') {

            let newDdl = cloneDdlBrancedProd.filter((d) => d.name.toLowerCase() === 'master');
            if (newDdl.length > 0) {

              newDdl[0].name = newDdl[0].name + " (Already Exist)";
              newDdl[0].disable = true;
            }
          }
          setDdlBranchesProd([...cloneDdlBrancedProd]);
        })
      }
    }
    else {

      if (type === 'dev') {
        setDdlBranchesDev([...ddlBranchesDevCached])
      }
      if (type === 'prod') {
        setDdlBranchesProd([...ddlBranchesProdCached])
      }

    }

  }




  const handleTabChange = async (event, newValue) => {

    setTabsAutoCICDValue((prevState) => ({ TabValue: newValue }));


  }

  const handleChangeManager = (e) => {
    setSelectedManager(e.target.value);


  }

  const handleChangeFramework = (e) => {
    setSelectedFramework(e.target.value)
    if (e.target.value === '.Net6') {
      setSelectedAppConfig('')
      setSelectedAppConfigUrl('')
      setSelectedMongo('')
      setSelectedEF('')

    }
  }

  const handleChangeSolutionType = (e) => {
    setSelectedSolutionType(e.target.value)
  }

  const handleSelectedTeam = (e) => {
    setSelectedTeam(e.target.value);

    let manager = listOfTeams.filter(l => l.id === e.target.value)
    if (manager.length > 0) {
      teamLeaderRef.value = manager[0].managerName;
      teamLeaderIdRef.value = manager[0].managerUserId;
      teamNameRef.value = manager[0].name;

      setSelectedSubDeperatmentManager('')
      setSelectedDeperatmentManager('')
      let teamLeaderSubDepartmentManager = listOfDepartments.filter(l => l.id === manager[0].departmentId);
      if (teamLeaderSubDepartmentManager && teamLeaderSubDepartmentManager.length > 0) {
        if (teamLeaderSubDepartmentManager[0].parentDepartmentId !== "") {
          setSelectedSubDeperatmentManager(teamLeaderSubDepartmentManager[0].id)
        }
        if (teamLeaderSubDepartmentManager[0].parentDepartmentId === "") {
          setSelectedDeperatmentManager(teamLeaderSubDepartmentManager[0].id)
        }

        let teamLeaderDepartmentManager = listOfDepartments.filter(l => l.id === teamLeaderSubDepartmentManager[0].parentDepartmentId);

        if (teamLeaderDepartmentManager && teamLeaderDepartmentManager.length) {
          setSelectedDeperatmentManager(teamLeaderDepartmentManager[0].id)
        }

      }


    }



  }

  const handleServiceDescription = (e) => {
    setServiceDescription(e.target.value)

  }
  const handleChangeTier = (e) => {
    setSelectedTier(e.target.value);
  }

  const handleChangeDBGroup = (e) => {
    setSelectedDBGroup(e.target.value)
  }

  const openPipelineTreeModal = (type) => {
    if (selectedProject === "") {
      const WarnMsg = () => (
        <div>Please select the project you're working in</div>
      )
      toast.warn(<WarnMsg />)
      return;
    }
    if (type === 'build') {
      setShowBuildPipelineTree(true)
    }
    else {
      setShowReleasePipelineTree(true)
    }

  }

  const handleAutoChange = (value) => {

    const autoCompleteArray = _.filter(repositories, element => _.includes(element.name.toLowerCase(), value.toLowerCase()));
    setOptions(autoCompleteArray)

  }

  const getOptionLabel = (option) => {
    return `${option.name}`;
  }

  const handleDelete = (value, newInput) => {


    setSelectedACRDev('')
    setSelectedACRProd('')
    if (newInput === "") {
      setDisableRepoName(false)
      setRepoNameFromAutoComplete("")

    }
    else {
      setDisableRepoName(true)
      setRepoNameFromAutoComplete(newInput)
    }
  }

  const handleChangeAppDdlConfig = (e) => {
    setSelectedAppConfig(e.target.value)
  }

  const handleAppConfigChange = (e) => {
    setSelectedAppConfigUrl(e.target.value)
  }

  const handleChangeAddMongo = (e) => {
    setSelectedMongo(e.target.value)
  }

  const handleChangeAddEF = (e) => {
    setSelectedEF(e.target.value)
  }


  const handleUserClick = () => { }

  return (
    <div>
      <div className="sc-header">
        <SCTop displaySecondLine={false} displayFilter={false} />
      </div>
      <div className="sc-main auto-cicd-container">

        <div className='cicd-how-to-title'>How to populate the self-service form?
          <a target='_blank' href='https://docs.google.com/document/d/1nI4MJ_GcTMt8YbIzES2ElkmSRe3tJ9M2UXXOCJB-T50/edit#heading=h.z6ne0og04bp5'>
            <span className='cicd-how-to-link'>Click Here</span>
          </a>
        </div>

        <Box sx={{ width: '100%', typography: 'body1' }} style={{ paddingLeft: '10px' }}>
          <TabContext value={tabsAutoCICDValue.TabValue} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

              <TabList ref={tabListRef} onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab className="sc-tab" label="Create Request" value="0" />
                <Tab className="sc-tab" label="View Requests" value="1" />
              </TabList>
            </Box>

            <TabPanel value="0" className='auto-cicd-flex-container'>

              <div className="auto-cicd-tab-one-container">
                <div className="auto-cicd-tab-one-first">
                  {
                    error ?
                      (
                        <div className={`auto-cicd-err-line ${shake ? " shake" : ''}`}><h6>All the fields below are required</h6></div>
                      ) :
                      (
                        <div></div>
                      )
                  }
                  {
                    errorClusterProd ?
                      (
                        <div className={`auto-cicd-err-line ${shake ? " shake" : ''}`}><h6>If you choose the cluster product, you need to populate the branch field, and if you choose the branch product, you need to populate the cluster field.</h6></div>
                      ) :
                      (
                        <div></div>
                      )
                  }
                  {
                    errorAppConfig &&
                    (
                      <div className={`auto-cicd-err-line ${shake ? " shake" : ''}`}><h6>Please enter AppConfiguration url.</h6></div>
                    )

                  }
                  {
                    regexError ?
                      (
                        <div className={`auto-cicd-err-line ${regexShake ? " shake" : ''}`}><h7>Solution name must consist of Capitalized Case characters and dot only</h7></div>
                      ) :
                      (
                        <div></div>
                      )
                  }
                  {
                    regexServiceError ?
                      (
                        <div className={`auto-cicd-err-line ${regexShake ? " shake" : ''}`}><h7>Service name could be with upper/lower case letters and numbers. Words may be separated with dashes (-)

                        </h7></div>
                      ) :
                      (
                        <div></div>
                      )
                  }
                  {
                    regexDBNameError ?
                      (
                        <div className={`auto-cicd-err-line ${regexShake ? " shake" : ''}`}><h7>DB name could be with upper/lower case letters and numbers

                        </h7></div>
                      ) :
                      (
                        <div></div>
                      )
                  }
                </div>
                <div className="auto-cicd-tab-one-line2first">
                  <div className='cicd-title'>Framework</div>
                  <div className='cicd-input'>
                    <SCDropDownList id="cicd-framework-type" className="md-ddl-edit" label="Select Framework *" componentState={selectedFramework} setComponentState={setSelectedFramework}
                      size="1000" items={ddlFramework} handleChange={(e) => handleChangeFramework(e)} value={selectedFramework} />
                  </div>


                  <div className='cicd-title'>Service</div>
                  <div className='cicd-input'>
                    <SCInputText id="cicd-service-name" refName={serviceNameRef} handleInputValueBlur={handleServiceNameBlur} required={true} size="small" className="md-input" label="Service Name" value={serviceNameValue}
                      handleInputValueChange={(e) => handleServiceNameChange(e)} />
                  </div>

                  <SCDropDownList id="cicd-solution-type" className="md-ddl-edit" label="Select Solution Type *" componentState={selectedSolutionType} setComponentState={setSelectedSolutionType}
                    size="1000" items={ddlSolutionType} handleChange={(e) => handleChangeSolutionType(e)} value={selectedSolutionType} />

                  <div className='auto-cicd-input-container'>

                    <SCTextArea rows={1.9} size="small" name='description' className="md-input" label="Service Description *"
                      handleInputValueChange={handleServiceDescription} value={serviceDescription} />
                  </div>
                  <div className='auto-cicd-flex-item'>
                    <SCDropDownList id="cicd-selected-team" className="md-ddl-edit" label="Team Name *" componentState={selectedTeam} setComponentState={setSelectedTeam}
                      size="1000" items={ddlTeams} handleChange={(e) => handleSelectedTeam(e)} value={selectedTeam} />

                    <SCDropDownList id="cicd-department-manager" className="md-ddl-edit" label="Department Manager *" componentState={selectedDeperatmentManager} setComponentState={setSelectedDeperatmentManager}
                      size="1000" items={ddlDeperatmentManager} handleChange={(e) => handleChangeDeperatmentManager(e)} value={selectedDeperatmentManager} />

                    <SCDropDownList id="cicd-sub-department-manager" className="md-ddl-edit" label="Sub Department Manager *" componentState={selectedSubDeperatmentManager} setComponentState={setSelectedSubDeperatmentManager}
                      size="1000" items={ddlSubDeperatmentManager} handleChange={(e) => handleChangeSubDeperatmentManager(e)} value={selectedSubDeperatmentManager} />



                    <div className='auto-cicd-input-container'>


                      <TextField

                        disabled={true}
                        sx={{
                          "& .MuiInputBase-root": {
                            width: 400
                          }
                        }}
                        inputProps={
                          { readOnly: true }
                        } value={teamLeaderRef.value} label="Team Leader *" InputLabelProps={{ shrink: true }} />


                    </div>

                    <SCDropDownList id="cicd-tier" className="md-ddl-edit" label="Tier *" componentState={selectedTier} setComponentState={setSelectedTier}
                      size="1000" items={ddlTier} handleChange={(e) => handleChangeTier(e)} value={selectedTier} />
                  </div>

                  <div className='cicd-title'>Repository</div>
                  <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Projects *" componentState={selectedProject} setComponentState={setSelectedProject}
                    size="1000" items={ddlProjects} handleChange={(e) => handleChangeProject(e)} value={selectedProject} />





                  <Autocomplete
                    freeSolo
                    id="search-repos"
                    disableClearable
                    options={options}
                    onChange={(e, value) => handleUserClick(e, value)}

                    getOptionLabel={getOptionLabel}
                    onInputChange={(event, newInputValue) => {
                      handleDelete(event, newInputValue);
                    }}
                    filterOptions={(options) => options}
                    renderInput={(params) => (
                      <TextField
                        className='cicd-repos'
                        {...params}
                        label="Existing Repositories"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",

                        }}

                        InputLabelProps={{ shrink: true }}
                        onChange={(event) =>
                          handleAutoChange(event.target.value)
                        }



                      />
                    )}


                  />

                  <div className='cicd-input'>
                    <SCInputText id="cicd-repository-name" required={true} size="small" className="md-input" label="New Repository" value={repoNameValue}
                      disabled={disableRepoName} handleInputValueChange={(e) => handleRepoNameChange(e)} />
                  </div>
                  <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Feed Name *" componentState={selectedFeed} setComponentState={setSelectedFeed}
                    size="1000" items={ddlFeeds} handleChange={(e) => handleChangeFeed(e)} value={selectedFeed} />
                  <div className='cicd-input'>
                    <SCInputText id="cicd-solution-name" refName={appNameRef} handleInputValueBlur={handleBlur} required={true} size="small" className="md-input" label="Solution Name" value={appNameValue}
                      handleInputValueChange={(e) => handleAppNameChange(e)} />
                  </div>

                </div>
                <div className="auto-cicd-tab-one-line2second">
                  <div className='cicd-title'>Pipelines Paths</div>



                  <div className='cicd-build-release'>

                    <span onClick={() => openPipelineTreeModal('build')} className='cicd-pipeline-tree-title' id='cicd-build-folders'>Build</span>
                    {
                      selectedBuildFolder === "" ?
                        (
                          <span className='cicd-pipeline-tree-value-not-selected'>Pipeline build folder was not selected</span>
                        ) :
                        (
                          <span className='cicd-pipeline-tree-value'>{selectedBuildFolder}</span>
                        )

                    }
                  </div>
                  <div className='cicd-build-release'>
                    <span onClick={() => openPipelineTreeModal('release')} className='cicd-pipeline-tree-title' id='cicd-release-folders'>Release</span>
                    {
                      selectedReleaseFolder === "" ?
                        (
                          <span className='cicd-pipeline-tree-value-not-selected'>Pipeline release folder was not selected</span>
                        ) :
                        (
                          <span className='cicd-pipeline-tree-value'>{selectedReleaseFolder}</span>
                        )

                    }
                  </div>

                  <div className='cicd-title'>Cluster</div>
                  <div className='cicd-selected-acr-container'>
                    <SCDropDownList id="cicd-selected-acr-dev" className="md-ddl-edit" label="Dev cluster*" componentState={selectedACRDev}
                      setComponentState={setSelectedACRDev}
                      size="1000" items={ddlAcrDev} handleChange={(e) => handleChangeACRDev(e)} value={selectedACRDev} />
                    <SCDropDownList id="cicd-branches-dev" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit" multiple={true} label="Branches *" componentState={selectedBranchesDev}
                      setComponentState={setSelectedBranchesDev} handleOpen={(e) => handleOpenDev(e)}
                      size="1000" items={ddlBranchesDev} handleChange={(e) => handleChangeBranchesDev(e)} value={selectedBranchesDev} />


                    <SCDropDownList id="cicd-selected-acr-prod" className="md-ddl-edit" label="Prod Cluster" componentState={selectedACRProd}
                      setComponentState={setSelectedACRProd}
                      size="1000" items={ddlAcrProd} handleChange={(e) => handleChangeACRProd(e)} value={selectedACRProd} />
                    <SCDropDownList id="cicd-branches-prod" className="md-ddl-edit" label="Branches" componentState={selectedBranchesProd}
                      setComponentState={setSelectedBranchesProd} handleOpen={(e) => handleOpenProd(e)}
                      size="1000" items={ddlBranchesProd} handleChange={(e) => handleChangeBranchesProd(e)} value={selectedBranchesProd} />
                  </div>


                  <div className='cicd-title'>Create Database</div>
                  <SCDropDownList id="cicd-selected-db-name" className="md-ddl-edit" label="Database Group" componentState={selectedDBGroup}
                    setComponentState={setSelectedDBGroup}
                    size="1000" items={ddlDBGroup} handleChange={(e) => handleChangeDBGroup(e)} value={selectedDBGroup} />
                  <div className='cicd-input'>
                    <SCInputText id="cicd-db-name" text="DB Name shouldn't contain the environment suffix e.g. DBName not DBName_Dev" size="small" handleInputValueBlur={handleDBNameBlur} className="md-input" label="Database Name" value={dbNameValue}
                      handleInputValueChange={(e) => handleDBNameChange(e)} />
                  </div>

                  {
                    selectedFramework === '.Net8' &&
                    (
                      <React.Fragment>
                        <div className='cicd-title'>App Configuration</div>
                        <SCDropDownList id="cicd-selected-db-name" className="md-ddl-edit" label="App Configuration" componentState={selectedAppConfig}
                          setComponentState={setSelectedAppConfig}
                          size="1000" items={ddlAppConfig} handleChange={(e) => handleChangeAppDdlConfig(e)} value={selectedAppConfig} />
                        <div className='cicd-input'>
                          <SCInputText id="cicd-db-name" text="Example: https://app-config-prod-infra.azconfig.io" size="small"
                            className="md-input" label="App Config Url" value={selectedAppConfigUrl}
                            handleInputValueChange={(e) => handleAppConfigChange(e)} />
                        </div>
                        <div className='cicd-title'>Add Mongo And Entity Framework</div>
                        <SCDropDownList id="cicd-selected-db-name" className="md-ddl-edit" label="Add Mongo" componentState={selectedMongo}
                          setComponentState={setSelectedMongo}
                          size="1000" items={ddlAppConfig} handleChange={(e) => handleChangeAddMongo(e)} value={setSelectedMongo} />

                        <SCDropDownList id="cicd-selected-db-name" className="md-ddl-edit" label="Add Entity Framework" componentState={selectedEF}
                          setComponentState={setSelectedEF}
                          size="1000" items={ddlAppConfig} handleChange={(e) => handleChangeAddEF(e)} value={setSelectedEF} />
                      </React.Fragment>
                    )
                  }

                  <div className='cicd-title'>Approval Manager</div>
                  <SCDropDownList id="cicd-approval-manager" className="md-ddl-edit" label="Select Approval Manager" componentState={selectedManager} setComponentState={setSelectedManager}
                    size="1000" items={ddlManager} handleChange={(e) => handleChangeManager(e)} value={selectedManager} />


                  <div className='cicd-button'>
                    <SCButton buttonId="selfServiceCreateButton" size="medium" text="Create Request" handleButtonClick={handleCreateRequest} />
                  </div></div>
              </div>




            </TabPanel>
            <TabPanel value="1">

              <div>

                <SCTable customColumnWidth="true" title="Self-Service Requests" columnsItems={columnsItems}
                  rowItems={requestCICDItems.data}
                  toolTip={{ column: 'processStatus', text: '', filter: { key: 'processStatus', operator: 'eq', value: 'failed', toolTipTextFromColumn: 'failureReason' } }}
                />

              </div>

            </TabPanel>

          </TabContext>
        </Box>


      </div>

      <AlertModal text={text} type={type} />
      <PipelineTreeModal show={showBuildPipelineTree} title="Build Pipeline Folder Tree"
        setSelectedValue={setSelectedBuildFolder} selectedValue={selectedBuildFolder}
        setShowPipelineTree={setShowBuildPipelineTree} projectName={selectedProject} />

      <PipelineTreeModal show={showReleasePipelineTree} title="Release Pipeline Folder Tree"
        setSelectedValue={setSelectedReleaseFolder} selectedValue={selectedReleaseFolder}
        setShowPipelineTree={setShowReleasePipelineTree} projectName={selectedProject} />




    </div>
  )
}

export default AutoCICD