import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzer.css';
import _ from "lodash";
import { Autocomplete, Box, Tab, TextField, Tooltip } from '@mui/material';
import { DateTime, DateTime as luxon } from "luxon";
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import SCDatePicker from '../SCDatePicker';
import SCButton from '../SCButton';
import SCTable from '../SCTable';
import ServiceAnalyzerTable from './ServiceAnalyzerTable';
import { toast } from 'react-toastify';
import SCContainer from '../SCContainer';
import { useHistory } from 'react-router-dom';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import ServiceAnalyzerSearch from './ServiceAnalyzerSearch';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ServiceAnalyzerSearchDateAndScan from './ServiceAnalyzerSearchDateAndScan';
import ServiceAnalyzerGroup from './ServiceAnalyzerGroup';
import ServiceAnalyzerHistory from './ServiceAnalyzerHistory';
import ServiceAnalyzerProgressBar from './ServiceAnalyzerProgressBar';









const ServiceAnalyzer = () => {


    const history = useHistory();
    const [progressBar, setProgressBar] = useState({})

    const [tabsValue, setTabsValue] = useState('0');
    const { token, servicesCached, isAdminUser, isEditorUser } = useContext(DataContext);
    const { scan, report, status, applications, getApplicationGroups, getHistory } = useContext(DataContextAnalyzer);
    const [options, setOptions] = useState([])
    const [inputValueApplicationId,setInputValueApplicationId] = useState(null)
    const [optionsCached, setOptionsCached] = useState([])
    const [scanHistoryRequestIds, setScanHistoryRequestIds] = useState([])
    const [optionsApplication, setOptionsApplication] = useState([])
    const [optionsApplicationCached, setOptionsApplicationCached] = useState([])

    const [valueTo, setValueTo] = useState(DateTime.local())
    const [valueFrom, setValueFrom] = useState(DateTime.local().minus({ hours: 1 }))
    const [selectedServices, setSelectedServices] = useState([])
    const [requestIDs, setRequestIDs] = useState([])
    const [anaylzerReport, setAnaylzerReport] = useState([])
    const [jobWorking, setJobWorking] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [selectedSearchByOption, setSelectedSearchByOption] = useState('0');
    //const [dataAnalysisStatus, setDataAnalysisStatus] = useState('')

    const [userProcessMessage, setUserProcessMessage] = useState('')

    const NUMBER_OF_PULLING = 20;

    const columnsItems = [
        { display: true, name: "serviceId", key: "serviceId" },
        { display: false, name: "id", key: "id" },
        { display: true, name: "name", key: "name" },
        { display: false, name: "clusterName", key: "clusterName" },
        { display: false, name: "newRelicName", key: "newRelicName" },
        { display: true, name: "project", key: "project" },
        { display: true, name: "resourceGroup/cluserName", key: "resourceGroup" },
        { display: false, name: "resourceId", key: "resourceId" },
        { display: false, name: "subscriptionId", key: "subscriptionId" },
        { display: true, name: "type", key: "type" }
    ]

    useEffect(()=>{

        console.log("progressBar",progressBar)

    },[progressBar])

    const arrayDifference = (arr1, arr2) =>{
        return arr1.filter(element => !arr2.includes(element));
      }
      

    useEffect(() => {


        if (anaylzerReport && anaylzerReport.length > 0) {

            
            let items = anaylzerReport.filter(a => a.status === "InProcess" && a.stop === true);
            if (items.length > 0) {
                let reportTempArray = [...items[0].services]
                
               
             

                reportTempArray.map((a) => {
                    if (a.collectDataStatus === "Pending" || a.collectDataStatus === "InProcess") {
                        a.collectDataStatus = "Unknown";
                    }
                    if (a.investigateStatus === "Pending" || a.investigateStatus === "InProcess") {
                        a.investigateStatus = "Unknown";
                    }
                  
                })
             
                console.log('!!!!!!!-DONE-!!!!!!!!')
                updateAnaylzerReport(items[0].reqId, reportTempArray, 'Done', true)
                setJobWorking(false)

                const hasSuccessPair = reportTempArray.some(status => 
                    status.investigateStatus === 'Succeded' && status.investigateStatus === 'Succeded'
                );
                if(!hasSuccessPair && progressBar.state === 3 ){
                    setProgressBar({ state: 8, text: 'Process Completed Successfully' })
                }


                //setDataAnalysisStatus('getReports')
            }
           
            // }
        }

    }, [anaylzerReport])





    useEffect(async () => {

        await getApplicationGroups()
        buildFilteredServices();
        //buildFilteredApplications();


    }, [])



    useEffect(() => {
       
        if (requestIDs.length > 0 && requestIDs.filter((r) => r.status === "pending").length > 0) {

            let functions = requestIDs.filter((r) => r.status === "pending");
            setProgressBar({state:2,text:'Starting...'})
            functions.forEach((func) => {

                startInterval(func)

            });

        }

    }, [requestIDs])




    const handleSearchByOptionChange = (event) => {
        setSelectedSearchByOption(event.target.value);//
        //clear the services table that the user selected = 1
        //clear the applications table that the user selected = 0    
        setSelectedServices([])
        setInputValue('')


    };

    const updateAnaylzerReport = (reqId, services, status, stop = false) => {

        
        let reports = [...anaylzerReport];
        let isExistArray = reports.filter(r => r.reqId === reqId);
        if (isExistArray.length > 0) {
            //update
            isExistArray[0].services = services;
            isExistArray[0].status = status;
            isExistArray[0].stop = stop;
        }
        else {
            //add
            let report = {
                reqId: reqId,
                services: services,
                status: status,
                stop: stop

            };
            isExistArray.push(report)

        }
        
        setAnaylzerReport([...isExistArray])




    }

    const startInterval = (func) => {
      
        const intervalId = setInterval(async () => {

            let analyzerObj = {
                reqId: func.reqId,
                services: {},
                status: 'InProcess',
                stop: false

            }

            try {
                if (progressBar.state < 3) {
                    setProgressBar({state:3,text:'Collect Services Data...'})
                }
                
                func.attemp++;
                let response = await status(func.reqId);

                if (response.status === 200) {

                    analyzerObj.services = response.data.data.services;


                    if (response.data.data.services.filter(ser =>
                        ser.collectDataStatus !== "InProcess"
                        && ser.collectDataStatus !== "Pending"
                        && ser.investigateStatus !== "InProcess"
                        && ser.investigateStatus !== "Pending"

                    ).length === response.data.data.services.length) {
                        clearInterval(intervalId)
                        analyzerObj.stop = true;


                    }
                    
                    if (func.attemp === func.retries) {
                        console.log("ATTEMP EQUAL TO LIMIT", func)
                        clearInterval(intervalId)
                        analyzerObj.stop = true;


                    }
                    updateAnaylzerReport(analyzerObj.reqId, analyzerObj.services, analyzerObj.status, analyzerObj.stop);


                }
                if (response.status !== 200) {
                    console.log("STATUS NOT EQUAL TO 200", response.status)
                    clearInterval(intervalId)
                    analyzerObj.stop = true;
                    updateAnaylzerReport(analyzerObj.reqId, analyzerObj.services, analyzerObj.status, analyzerObj.stop);

                }


            }
            catch (error) {
                console.log("Error", error)
                clearInterval(intervalId)
                analyzerObj.stop = true;
                updateAnaylzerReport(analyzerObj.reqId, analyzerObj.services, analyzerObj.status, analyzerObj.stop);

            }


        }, 10000);
    }

    const isNotNullUndefinedOrEmpty = (str) => {
        return str !== null && str !== undefined && str.trim() !== '';
    }


    const buildFilteredApplications = () => {
        if (applications && applications.length > 0) {
            var apps = []

            applications.map((app) => {
                apps.push({ id: app.id, name: app.name })
            })
            setOptionsApplication(apps)
            setOptionsApplicationCached(apps)

            return apps;

        }
    }

    const buildFilteredServices = () => {

        if (servicesCached && servicesCached.length > 0) {
            var services = []

            let filterServicesCached = servicesCached?.filter(s =>
                (!s.serviceType.toLowerCase().startsWith('aks') &&
                    isNotNullUndefinedOrEmpty(s.serviceMetadata?.newRelicAppName) &&
                    isNotNullUndefinedOrEmpty(s.portalUrl)
                ) || (s.serviceType.toLowerCase().startsWith('aks'))) //portal url is for the AppServiceResourceId

            filterServicesCached.map((p) => {

                if (p.serviceType.toLowerCase().startsWith('aks')) {
                    services.push({ serviceId: p.id, project: p.azureDevopsProjectName, subscriptionId: p.subscriptionId, resourceGroup: p.resourceGroup, id: p.azureDevopsReleaseDefinitionId, type: p.serviceType, name: p.name, newRelicName: p.serviceMetadata.newRelicAppName, resourceId: "", clusterName: p?.aksInfo?.clusterName })
                }
                else {
                    services.push({ serviceId: p.id, project: p.azureDevopsProjectName, subscriptionId: p.subscriptionId, resourceGroup: p.resourceGroup, id: p.azureDevopsReleaseDefinitionId, type: p.serviceType, name: p.name, newRelicName: p.serviceMetadata.newRelicAppName, resourceId: p.portalUrl, clusterName: "" })
                }

            })
            setOptions(services)
            setOptionsCached(services)
            return services;
        }


    }


    const getOptionLabel = (option) => {
        return `${option.id} - ${option.name} - (${option.type})`;
    };


    const getOptionLabelApp = (option) => {

        return `${option.name}`;
    };


    const handleUserClickOnSearchApplication = (e, value) => {

        setInputValue(value.name)
        setInputValueApplicationId(value.id)
        //support just one application at the same time//
        if (jobWorking) {

            commandNotAllowed();
            return;
        }
        let group = applications.find(x => x.id === value.id)
        let services = [];
        group.serviceIds.map((s) => {
            let service = servicesCached.find(x => x.id === s);


            if (service.serviceType.toLowerCase().startsWith('aks')) {
                services.push({ serviceId: service.id, project: service.azureDevopsProjectName, subscriptionId: service.subscriptionId, resourceGroup: service.resourceGroup, id: service.azureDevopsReleaseDefinitionId, type: service.serviceType, name: service.name, newRelicName: service.serviceMetadata.newRelicAppName, resourceId: null, clusterName: service?.aksInfo?.clusterName })
            }
            else {
                services.push({ serviceId: service.id, project: service.azureDevopsProjectName, subscriptionId: service.subscriptionId, resourceGroup: service.resourceGroup, id: service.azureDevopsReleaseDefinitionId, type: service.serviceType, name: service.name, newRelicName: service.serviceMetadata.newRelicAppName, resourceId: service.portalUrl })
            }

        })
        setSelectedServices([...services])





    }
    const handleUserClick = (e, value) => {

        setInputValue(value.id + " - " + value.name + " (" + value.type + ") ")
        if (jobWorking) {

            commandNotAllowed();
            return;
        }

        let servicesArr = [...selectedServices];
        let selectedUserService = {
            serviceId: value.serviceId,
            id: value.id,
            name: value.name,
            newRelicName: value.newRelicName,
            project: value.project,
            resourceGroup: value.resourceGroup,
            resourceId: value.resourceId,
            subscriptionId: value.subscriptionId,
            type: value.type,

        }

        servicesArr.push(selectedUserService);
        setSelectedServices([...servicesArr])

    }
    const handleAutoApplicationChange = (value) => {

        if (jobWorking) {

            commandNotAllowed();
            return;
        }
        setInputValue(value)

        /*if (value === '') {
            buildFilteredApplications();
            return;
        }*/
        let ops = buildFilteredApplications();
        const autoCompleteArray = _.filter(ops, element => _.includes(element.name.toLowerCase(), value.toLowerCase()));
        setOptionsApplication(autoCompleteArray)

    }
    const handleAutoChange = (value) => {



        if (jobWorking) {

            commandNotAllowed();
            return;
        }
        setInputValue(value)
        /*if (value === '') {
            buildFilteredServices();
            return;
        }*/
        let ops = buildFilteredServices();
        const autoCompleteArray = _.filter(ops, element => _.includes(element.name.toLowerCase(), value.toLowerCase()));
        setOptions(autoCompleteArray)

    }



    const handleGetData = async (applicationId = null) => {
        if (jobWorking) {

            commandNotAllowed();
            return;
        }
        //setUserProcessMessage('')
        setJobWorking(true);
        setAnaylzerReport([])
        setRequestIDs([])
        //setDataAnalysisStatus('')
        setProgressBar({})
        let services = [...selectedServices];
        let servicesIds = [];

        services.map((s) => {
            servicesIds.push(s.serviceId)
        })

        try {
            setProgressBar({state:1,text:'Generating Request Id...'})
            let response = await scan(servicesIds, valueFrom, valueTo,applicationId);

            if (response.data.success && response.data.requestId) {
                //let reqIDs = [...requestIDs];
                let reqIDs = [];
                reqIDs.push({ reqId: response.data.requestId, status: "pending", report: {}, attemp: 0, retries: NUMBER_OF_PULLING, services: services });
                setRequestIDs([...reqIDs])
                setInputValueApplicationId(null)
                
            }
        }
        catch (error) {
            setInputValueApplicationId(null)
        }

    }

    const commandNotAllowed = () => {
        const ErrorMsg = () => (
            <div>The command is not allowed during the analysis process.</div>
        )
        toast.error(<ErrorMsg />)
    }

    const handleRequestRemove = (e) => {

        if (jobWorking) {

            commandNotAllowed();
            return;
        }
        let removeItem = [...selectedServices];
        removeItem.splice(e, 1);
        setSelectedServices([...removeItem])
    }

    const getPulling = (r) => {

        return r.attemp + "/" + r.retries;
    }

    const redirectToCreateApplication = () => {
        history.push('/analyzerGroup');
    }

    const handleTabChange = async (event, newValue) => {

        setSelectedServices([])
        setAnaylzerReport([])
        //setUserProcessMessage('')
        setInputValue('')
        setTabsValue(newValue);
    }

    const handleGetScanHistory = async () => {

        let services = "";
        if (selectedServices.length === 1) {
            services = 'Services=' + selectedServices[0].serviceId + "&From=" + valueFrom + "&To=" + valueTo
        }
        else {
            selectedServices.map((service, index) => {
                if (index === 0) {
                    services = 'Services=' + service.serviceId
                }
                else {
                    services += '&Services=' + service.serviceId
                }
            })
            services += "&From=" + valueFrom
            services += "&To=" + valueTo
        }



        let response = await getHistory(services);
        setScanHistoryRequestIds(response.data.data)
    }

    return (
        <React.Fragment>
            {/*
                isAdminUser || isEditorUser ?
                    (
                        <div className='custom-link-button ' onClick={() => redirectToCreateApplication()}>Manage Applications</div>
                    ) :
                    (
                        <React.Fragment />
                    )
                    */}

            <div className='service-analyzer-container'>


                <Box sx={{ width: '100%', typography: 'body1' }} style={{ paddingLeft: '10px' }}>
                    <TabContext value={tabsValue} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab disabled={jobWorking && tabsValue !== "0"} className="sc-tab" label="Scan By Services" value="0" />
                                <Tab disabled={jobWorking && tabsValue !== "1"} className="sc-tab" label="Scan By Applications" value="1" />
                                <Tab disabled={jobWorking && tabsValue !== "2"} className="sc-tab" label="Analyzer History" value="2" />
                                {
                                    isAdminUser || isEditorUser ?
                                        (
                                            <Tab disabled={jobWorking && tabsValue !== "3"} className="sc-tab" label="Manage Applications" value="3" />
                                        ) :
                                        (
                                            <React.Fragment />
                                        )
                                }
                            </TabList>
                        </Box>
                        <TabPanel value="0">

                            <div className='service-analyzer-padding'>
                                <ServiceAnalyzerSearch
                                    options={options} handleAutoCompleteOnChangeClick={handleUserClick}
                                    jobWorking={jobWorking} getOptionLabel={getOptionLabel}
                                    inputValue={inputValue} handleAutoCompleteAutoChange={handleAutoChange}
                                    label={"Search Service"}
                                    optionsCached={optionsCached} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <ServiceAnalyzerSearchDateAndScan jobWorking={jobWorking}
                                    setValueFrom={setValueFrom} valueFrom={valueFrom}
                                    setValueTo={setValueTo} valueTo={valueTo} handleGetData={handleGetData} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <SCTable title="Services" columnsItems={columnsItems} rowItems={selectedServices}
                                    handleRemove={(event) => handleRequestRemove(event)}
                                    includesRemoveButton={true} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <SCButton size="medium" text="Scan" handleButtonClick={() => handleGetData()} />
                            </div>

                        </TabPanel>
                        <TabPanel value="1">

                            <div className='service-analyzer-padding'>
                                <ServiceAnalyzerSearch
                                    options={optionsApplication} handleAutoCompleteOnChangeClick={handleUserClickOnSearchApplication}
                                    jobWorking={jobWorking} getOptionLabel={getOptionLabelApp}
                                    inputValue={inputValue} handleAutoCompleteAutoChange={handleAutoApplicationChange}
                                    label={"Search Application"}
                                    optionsCached={optionsCached} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <ServiceAnalyzerSearchDateAndScan jobWorking={jobWorking}
                                    setValueFrom={setValueFrom} valueFrom={valueFrom}
                                    setValueTo={setValueTo} valueTo={valueTo} handleGetData={handleGetData} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <SCTable title="Services" columnsItems={columnsItems} rowItems={selectedServices} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <SCButton size="medium" text="Scan" handleButtonClick={() => handleGetData(inputValueApplicationId)} />
                            </div>

                        </TabPanel>

                        <TabPanel value="2">

                            <div className='service-analyzer-padding'>
                                <ServiceAnalyzerSearch
                                    options={options} handleAutoCompleteOnChangeClick={handleUserClick}
                                    jobWorking={jobWorking} getOptionLabel={getOptionLabel}
                                    inputValue={inputValue} handleAutoCompleteAutoChange={handleAutoChange}
                                    label={"Search Service"}
                                    optionsCached={optionsCached} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <ServiceAnalyzerSearchDateAndScan jobWorking={jobWorking}
                                    setValueFrom={setValueFrom} valueFrom={valueFrom}
                                    setValueTo={setValueTo} valueTo={valueTo} handleGetData={handleGetData} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <SCTable title="History For Services" columnsItems={columnsItems} rowItems={selectedServices}
                                    handleRemove={(event) => handleRequestRemove(event)}
                                    includesRemoveButton={true} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <SCButton customClass="service-analyzer-get-history-btn" size="medium" text="Get Scan History" handleButtonClick={() => handleGetScanHistory()} />
                            </div>

                            <div className='service-analyzer-padding'>
                                <ServiceAnalyzerHistory data={scanHistoryRequestIds} />
                            </div>


                        </TabPanel>
                        <TabPanel value="3">
                            <ServiceAnalyzerGroup />
                        </TabPanel>

                    </TabContext>
                </Box>


            </div>
            <div className='service-analyzer-box-3'>
                {
                    requestIDs.length > 0 && (tabsValue === "0" || tabsValue === "1") ?
                        (
                            requestIDs.map((r, index) => (
                                <React.Fragment>
                                    {/*
                                        anaylzerReport.length === 0 ?
                                            (
                                                <div key={`process-msg-element` + index} className="analyzer-service-process-message">Starting to scan your request, it should take a while, we're scanning the services and dependencies...</div>
                                            ) : anaylzerReport.filter(a => a.reqId === r.reqId)[0].status !== "Done" ?
                                                (
                                                    <div key={`fetch-msg-element` + index} className="analyzer-service-process-message"> Fetching data, and get status for each service. Pulling number: {getPulling(r)}</div>
                                                ) : (
                                                    <div key={`fetch-msg-element` + index} className="analyzer-service-process-message">{userProcessMessage}</div>
                                                )
                                                */}
                                    <ServiceAnalyzerProgressBar state={progressBar.state} text={progressBar.text} />
                                    <ServiceAnalyzerTable data={anaylzerReport.filter(a => a.reqId === r.reqId)[0]} reqId={r.reqId}
                                    progressBar={progressBar} setProgressBar={setProgressBar}   setUserProcessMessage={setUserProcessMessage}  />
                                </React.Fragment>
                            ))

                        ) : (<span></span>)
                }

            </div>


        </React.Fragment>
    )
}

export default ServiceAnalyzer