import React, { useEffect, useRef, useState, useContext } from 'react'
import SCTop from "../Components/SCTop";
import './ManageUsers.css';
import SCInputText from "../Components/SCInputText";
import SCButton from "../Components/SCButton";
import UserAutoComplete from '../Components/UserAutoComplete';
import SCDropDownList from '../Components/SCDropDownList';
import * as consts from '../consts';
import axios from '../axios';
import { v4 as uuid } from 'uuid';
import DataContext from "../Context/DataContext";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from 'react-toastify';
import SCTable from '../Components/SCTable';
import _ from "lodash";

import Box from '@mui/material/Box';
import Tab1 from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import useRefInputUserRefCallback from '../Hooks/Ref/useRefInputUserRefCallback'
import useRefInputEmailRefCallback from '../Hooks/Ref/useRefInputEmailRefCallback'
import useRefInputEmailTakeawayRefCallback from '../Hooks/Ref/useRefInputEmailTakeawayRefCallback'

import useRefInputDeptNameRefCallback from '../Hooks/Ref/useRefInputDeptNameRefCallback'
import useRefInputSlackDeptRefCallback from '../Hooks/Ref/useRefInputSlackDeptRefCallback'
import useRefInputdescriptionDeptRefCallback from '../Hooks/Ref/useRefInputdescriptionDeptRefCallback'

import useRefInputDescriptionRefCallback from '../Hooks/Ref/useRefInputDescriptionRefCallback'
import useRefInputSlackRefCallback from '../Hooks/Ref/useRefInputSlackRefCallback'
import useRefInputTeamNameRefCallback from '../Hooks/Ref/useRefInputTeamNameRefCallback'
import useAxios from '../Hooks/useAxios';

const ManageUsers = () => {

  const instance = useAxios(process.env.REACT_APP_BASE_URL);

  const { token, getTeams, getUsers, getDepartment, setListOfDepartments, setListOfUsers, listOfUsers, setListOfTeams, listOfTeams } = useContext(DataContext);

  const inputUserRef = useRefInputUserRefCallback({})
  //const inputUserRef = useRef('');

  const inputEmailRef = useRefInputEmailRefCallback({})
  //const inputEmailRef = useRef('');

  const inputEmailTakeawayRef = useRefInputEmailTakeawayRefCallback({})
  //const inputEmailTakeawayRef = useRef('');

  const inputDeptNameRef = useRefInputDeptNameRefCallback({})
  //const inputDeptNameRef = useRef('');

  const inputSlackDeptRef = useRefInputSlackDeptRefCallback({})
  //const inputSlackDeptRef = useRef('');

  const inputdescriptionDeptRef = useRefInputdescriptionDeptRefCallback({})
  //const inputdescriptionDeptRef = useRef(''); 


  const inputDescriptionRef = useRefInputDescriptionRefCallback({})
  //const inputDescriptionRef = useRef('');

  const inputSlackRef = useRefInputSlackRefCallback({})
  //const inputSlackRef = useRef('');

  const inputTeamNameRef = useRefInputTeamNameRefCallback({})
  //const inputTeamNameRef = useRef('');




  const [ddlDeperatment, setDdlDeperatment] = useState([]);

  const [selectedDeperatment, setSelectedDeperatment] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const [selectedDeperatmentFromTeam, setSelectedDeperatmentFromTeam] = useState("");
  const [teamLeader, setTeamLeader] = useState("");

  const [ddlTeams, setDdlTeams] = useState([]);
  const [selectedTeamForMember, setSelectedTeamForMember] = useState("");

  const [selectedUser, setSelectedUser] = useState({})
  const [selectedDept, setSelectedDept] = useState('')
  const [selectedTeam, setSelectedTeam] = useState('')
  const [selectedTeamMember, setSelectedTeamMember] = useState('')

  const [teamMemberRO, setTeamMemberRO] = useState(false)


  const columnsItems = [{ display: true, name: "Id", key: "Id" },
  { display: true, name: "Name", key: "Name" },
  { display: false, name: "email", key: "email" },
  { display: false, name: "emailTakeaway", key: "emailTakeaway" },
  { display: false, name: "description", key: "description" },
  { display: false, name: "manager", key: "manager" },
  { display: false, name: "managerUserId", key: "managerUserId" },
  { display: false, name: "parentDepartmentId", key: "parentDepartmentId" },
  { display: false, name: "slackChannel", key: "slackChannel" },
  { display: false, name: "azureUserId", key: "azureUserId" },
  { display: false, name: "managerName", key: "managerName" },
  { display: false, name: "roleInTeam", key: "roleInTeam" },
  { display: false, name: "departmentId", key: "departmentId" }
  ];

  const columnsMembersItems = [{ display: true, name: 'Id', key: 'Id' },
  { display: true, name: 'Team', key: 'Team' },
  { display: false, name: 'TeamId', key: 'TeamId' },
  { display: true, name: 'Member Name', key: 'MemberName' }
  ]

  const [userTableItems, setUserTableItems] = useState([]);
  const [deptTableItems, setDeptTableItems] = useState([]);
  const [teamTableItems, setTeamTableItems] = useState([]);
  const [teamMembersTableItems, setTeamMembersTableItems] = useState([]);



  const [deptManagerId, setDeptManagerId] = useState()
  const [deptManagerName, setDeptManagerName] = useState()
  const [teamManagerName, setTeamManagerName] = useState()
  const [teamMemberId, setTeamMemberId] = useState()
  const [teamMemberName, setTeamMemberName] = useState()

  const [isCheckBoxsNull, setIsCheckBoxsNull] = useState(true)
  const [isCheckBoxsTeamsNull, setIsCheckBoxsTeamsNull] = useState(true)
  const [isCheckBoxsDeptsNull, setIsCheckBoxsDeptsNull] = useState(true)
  const [isCheckBoxsTeamsMembersNull, setIsCheckBoxsTeamsMembersNull] = useState(true)


  const [clearCheckBoxUsersLists, setClearCheckBoxUsersLists] = useState(false)

  const [userRoles, setUserRoles] = useState([])
  const [tabsValue, setTabsValue] = useState('0');






  useEffect(() => {
    if (token) {
      Promise.all([getManageUsers(), getManageDepartment(), getManageTeams(), getRoles()]).then((results) => { }).catch((error) => { })
    }
  }, [token])

  const handleChangeDeperatment = (e) => {
    setSelectedDeperatment(e.target.value);
  }
  const handleChangeRole = (e) => {
    setSelectedRole(e.target.value);
  }

  const handleChangeDeperatmentFromTeam = (e) => {
    setSelectedDeperatmentFromTeam(e.target.value);
  }

  const handleChangeTeamForMember = (e) => {
    setSelectedTeamForMember(e.target.value);

  }

  const getManageDepartment = async () => {


    getDepartment().then((response) => {
      var deptRows = [];
      if (response.data.length > 0) {
        var deptManagerList = [];
        setListOfDepartments(response.data);
        response?.data?.map((dept) => {
          deptManagerList.push({
            name: dept.name,
            value: dept.id
          })
          deptRows.push({
            id: dept.id, name: dept.name, description: dept.description, managerUserId: dept.managerUserId
            , managerName: dept.manager, parentDepartmentId: dept.parentDepartmentId, slackChannel: dept.slackChannel
          })
        })

        setDdlDeperatment(deptManagerList);
        setDeptTableItems([...deptRows])
      }
      else {
        setListOfDepartments([])
        setDeptTableItems([])
        setDdlDeperatment([])
      }
    }).catch((error) => {
      console.log('get department error', error);
    })




  }

  useEffect(() => {
    if (isCheckBoxsNull === true) {
      resetUserFields()
    }
  }, [isCheckBoxsNull])


  const resetTeamMembderFields = () => {
    setTeamMemberId(null);
    setTeamMemberName();
    setSelectedTeamForMember(null)
    setTeamMemberRO(false)

  }
  const resetTeamFields = () => {

    if (inputTeamNameRef?.current?.value) {
      inputTeamNameRef.current.value = "";
    }

    if (inputDescriptionRef?.current?.value) {
      inputDescriptionRef.current.value = "";
    }

    if (inputSlackRef?.current?.value) {
      inputSlackRef.current.value = "";
    }

    setSelectedDeperatmentFromTeam(null)
    setTeamLeader(null)
    setTeamManagerName(null)
  }

  const resetDeptFields = () => {

    if (inputDeptNameRef) {
      if (Object.keys(inputDeptNameRef).length > 0)
        inputDeptNameRef.current.value = "";
    }

    if (inputdescriptionDeptRef) {
      if (Object.keys(inputdescriptionDeptRef).length > 0)
        inputdescriptionDeptRef.current.value = "";
    }

    if (inputSlackDeptRef) {
      if (Object.keys(inputSlackDeptRef).length > 0)
        inputSlackDeptRef.current.value = "";
    }

    setSelectedDeperatment(null)
    setDeptManagerName(null)
  }

  const resetUserFields = () => {

    if (inputEmailTakeawayRef?.current?.value) {
      inputEmailTakeawayRef.current.value = "";
    }


    if (inputUserRef?.current?.value) {
      inputUserRef.current.value = "";
    }

    if (inputEmailRef?.current?.value) {
      inputEmailRef.current.value = "";
    }


    setSelectedRole(null)
    
  }



  const handleCreateUserClick = () => {
    if (inputUserRef.current.value && inputEmailRef.current.value && inputEmailTakeawayRef.current.value) {

      return instance.post(consts.requests.createUser.toString(), {
        emailTakeaway: inputEmailTakeawayRef.current.value,
        azureUserId: uuid(),
        name: inputUserRef.current.value,
        email: inputEmailRef.current.value,
        roleInTeam: selectedRole,
        teamId: ""
      }, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (response) => {
     
        if(!response){
          return;
        }
        resetUserFields()      
        await getManageUsers();
        const SuccessMsg = () => (
          <div>
            <div>New user was created successfully.</div>
          </div>
        )
        toast.success(<SuccessMsg />)
      }).catch((error) => {
        console.log('create new user error', error)
      })

    }
  }
  const getRoles = () => {
    const response = instance.get(consts.requests.getRoles, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {

      if (response.status === 200) {
        const roleSet = [];
        for (const [key, value] of Object.entries(response.data)) {

          roleSet.push({ name: key, value: value })
        }
        setUserRoles(roleSet)
      }

    }).catch((error) => {

    });
  }
  const getManageUsers = async () => {

    getUsers().then((response) => {
      if (response.data.length > 0) {
        setListOfUsers(response.data)
        fillUsers(response.data)
        return true;
      }
    }).catch((error) => {

    });



  }


  const getManageTeams = async () => {


    getTeams().then((response) => {
      var teamRows = [];
      var teamsManageList = [];
      if (response.data.length > 0) {
        setListOfTeams(response.data)
     
        response?.data?.map((dept) => {
          teamRows.push({
            id: dept.id, name: dept.name, description: dept.description, managerUserId: dept.managerUserId
            , departmentId: dept.departmentId, managerName: dept.managerName, parentDepartmentId: dept.parentDepartmentId, slackChannel: dept.slackChannel
          })
          teamsManageList.push({
            name: dept.name,
            value: dept.id
          })

        })

        setDdlTeams([...teamsManageList])
        setTeamTableItems([...teamRows])
        initMembersInTeams(response.data);
      }
      else {
        setListOfTeams([])
        setTeamTableItems([])
        setDdlTeams([])
      }
    }).catch((error) => {
      console.log('get team error', error);
    });

  }

  const initMembersInTeams = (listOfTeams) => {


    let teamMembersRows = [];


    listOfTeams.map((m) => {
      if (m.usersInTeam.length > 0) {
        m.usersInTeam.map((u) => {
          teamMembersRows.push({ id: u.id, team: m.name, teamId: m.id, memberName: u.name })
        })
      }

    })
    setTeamMembersTableItems([...teamMembersRows])
  }

  useEffect(() => {
    if (isCheckBoxsDeptsNull === true) {
      resetDeptFields()
    }
  }, [isCheckBoxsDeptsNull])

  const alertMandatoryMessage = () => {
    const WarnMsg = () => (
      <div>
        <div>Department name is a mandatory field.</div>
      </div>
    )
    toast.warn(<WarnMsg />)
  }
  const getParentDepartmentId = () => {
    if (!selectedDeperatment){
      return "";
    }
     
    if (Object.entries(selectedDeperatment).length > 0) {
      return selectedDeperatment;
    }


  }
  const handleCreateDepartmentClick = () => {
    if (inputDeptNameRef.current.value === '') {
      alertMandatoryMessage()
      return;
    }
    if (inputDeptNameRef.current.value && deptManagerId) {


      return instance.post(consts.requests.postDepartment.toString(), {
        name: inputDeptNameRef?.current?.value,
        description: inputdescriptionDeptRef?.current?.value,
        managerUserId: deptManagerId,
        slackChannel: inputSlackDeptRef?.current?.value,
        parentDepartmentId: getParentDepartmentId()
      }, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (response) => {
        //refresh after department created
        if(!response){
          return;
        }
        await getManageDepartment()
        resetDeptFields();
        const SuccessMsg = () => (
          <div>
            <div>Department was created successfully.</div>
          </div>
        )
        toast.success(<SuccessMsg />)
      }).catch((error) => {
        console.log('create new department error', error)
      })

    }
  }

  useEffect(() => {
    if (isCheckBoxsTeamsNull === true) {
      resetTeamFields()
    }
  }, [isCheckBoxsTeamsNull])





  const handleTableTeamMembersRemove = (e) => {
    const url = consts.requests.deleteMemberFromTeam.toString().replace('{userId}', teamMembersTableItems[e].id);
    return instance.delete(url,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        if (res?.status === 200) {
          //refresh users list after delete

          let currentTeam = listOfTeams.filter(t => t.id === teamMembersTableItems[e].teamId);

          _.remove(currentTeam[0].usersInTeam, {
            id: teamMembersTableItems[e].id
          });

          initMembersInTeams(listOfTeams)

          const SuccessMsg = () => (
            <div>
              <div>Member was removed successfully from his team.</div>
            </div>
          )
          toast.success(<SuccessMsg />)
        }
      }).catch(err => {

        console.log(err);
      });


  }

  const handleTableTeamUpdate = () => {


    const url = consts.requests.updateTeam.toString().replace('{teamId}', selectedTeam.id);

    instance.put(url, {
      name: inputTeamNameRef.current.value,
      nameAliases: [],
      description: inputDescriptionRef.current.value,
      managerUserId: teamLeader,
      parentTeamId: "",
      departmentId: selectedDeperatmentFromTeam,
      slackChannel: inputSlackRef.current.value

    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(async (res) => {
      if (res?.status === 200) {
        resetTeamFields()
        //refresh after team updated
        await getManageTeams()
        const SuccessMsg = () => (
          <div>
            <div>Team was updated successfully.</div>
          </div>
        )
        toast.success(<SuccessMsg />)
      }
    }).catch(err => {

      console.log(err);
    });


  }
  const handleCreateTeamClick = () => {
    if (inputTeamNameRef.current.value) {
      //
      return instance.post(consts.requests.postTeam.toString(), {
        name: inputTeamNameRef.current.value,
        nameAliases: [],
        description: inputDescriptionRef.current.value,
        managerUserId: teamLeader,
        parentTeamId: '',
        departmentId: selectedDeperatmentFromTeam,
        slackChannel: inputSlackRef?.current?.value
      }, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (response) => {
        resetTeamFields()
        //refresh after team created
        await getManageTeams()
        const SuccessMsg = () => (
          <div>
            <div>New team was created successfully.</div>
          </div>
        )
        toast.success(<SuccessMsg />)
      }).catch((error) => {
        console.log('create new team error', error)
      })

    }
  }



  const handleAutoTeamLeadClick = (e, obj) => {

   
    setTeamLeader(obj.value)
    setTeamManagerName({ label: obj.label, value: obj.value })

  }

  const handleAutoDeptManagerClick = (e, obj) => {

  
    setDeptManagerId(obj.value)
    setDeptManagerName({ label: obj.label, value: obj.value })

  }
  const handleAutoTeamMemberClick = (e, obj) => {
    if (obj !== null) {

      //setTeamMemberId(obj.id)
      setTeamMemberId({ label: obj.label, value: obj.value })
      setTeamMemberName(obj.label)
    }



  }


  const fillUsers = (users) => {
    var userRows = [];

    users.forEach(element => {
      userRows.push({
        id: element.id, name: element.name, azureUserId: element.azureUserId,
        roleInTeam: element.roleInTeam, emailTakeaway: element.emailTakeaway, email: element.email
      });

    });
    setUserTableItems([...userRows])

  }


  const handleTableUserUpdate = () => {


    const url = consts.requests.updateUser.toString().replace('{azureUserId}', selectedUser.id);

    instance.put(url, {
      emailTakeaway: inputEmailTakeawayRef.current.value,
      azureUserId: selectedUser?.azureUserId,
      name: inputUserRef.current.value,
      email: inputEmailRef.current.value,
      roleInTeam: selectedRole,
      teamId: ""
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(async (res) => {
      if (res?.status === 200) {
        resetUserFields()       
        setSelectedUser({})
        setClearCheckBoxUsersLists(true)
        await getManageUsers()
        const SuccessMsg = () => (
          <div>
            <div>User updated successfully.</div>
          </div>
        )
        toast.success(<SuccessMsg />)
      }
    }).catch(err => {

      console.log(err);
    });


  }
  const handleTableUserRemove = (e) => {

    const url = consts.requests.deleteUser.toString().replace('{azureUserId}', userTableItems[e].id);
    return instance.delete(url,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        if (res?.status === 200) {
          resetUserFields()
          await getManageUsers()
          const SuccessMsg = () => (
            <div>
              <div>User was removed successfully.</div>
            </div>
          )
          toast.success(<SuccessMsg />)
        }
      }).catch(err => {

        console.log(err);
      });



  }




  const handleTableDeptUpdate = () => {

    const url = consts.requests.updateDepartment.toString().replace('{departmentId}', selectedDept.id);


    instance.put(url, {
      name: inputDeptNameRef.current.value,
      managerUserId: deptManagerName.id,
      parentDepartmentId: selectedDeperatment,
      description: inputdescriptionDeptRef.current.value,
      slackChannel: inputSlackDeptRef.current.value
    }, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res?.status === 200) {

        setSelectedDept('')
        resetDeptFields()
        const SuccessMsg = () => (
          <div>
            <div>Service information was saved successfully.</div>
          </div>
        )
        toast.success(<SuccessMsg />)
      }
    }).catch(err => {

      console.log(err);
    });

  }
  const handleTableDeptRemove = (e) => {

    const url = consts.requests.removeDepartment.toString().replace('{departmentId}', deptTableItems[e].id);
    return instance.delete(url,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        if (res?.status === 200) {
          //refresh after department removed
          await getManageDepartment()
          const SuccessMsg = () => (
            <div>
              <div>Department was removed successfully.</div>
            </div>
          )
          toast.success(<SuccessMsg />)
        }
      }).catch(err => {

        console.log(err);
      });
  }


  const handleTableUserSelect = (e) => {

    if (userTableItems[e].id === selectedUser.id) {
      inputUserRef.current.value = "";
      inputEmailRef.current.value = "";
      inputEmailTakeawayRef.current.value = "";

      setSelectedUser({})
    }
    else {

      inputUserRef.current.value = userTableItems[e].name
      inputEmailRef.current.value = userTableItems[e].email
      inputEmailTakeawayRef.current.value = userTableItems[e].emailTakeaway
      setSelectedUser(userTableItems[e])
      if (userTableItems[e].roleInTeam != null && userTableItems[e].roleInTeam != "") {
        setSelectedRole(userTableItems[e].roleInTeam)
      }

      else {
        setSelectedRole('')
      }
    }
  }


  const upsertUserMemnder = async () => {

    if (teamMemberId && selectedTeamForMember) {

      let url = consts.requests.createTeamMember.toString().replace('{userId}', teamMemberId.value).replace('{teamId}', selectedTeamForMember);

      return await instance.post(url, {}, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    }
  }
  const handleUpdateTeamMemberClick = async () => {
    let res = await upsertUserMemnder();
    if (res?.status === 200) {
      resetTeamMembderFields()

      let userPreviousTeam = _.filter(listOfTeams,
        {
          usersInTeam: [{ id: teamMemberId.value }]
        }
      );

      let member = userPreviousTeam[0].usersInTeam.filter(u => u.id === teamMemberId.value);

      _.remove(userPreviousTeam[0].usersInTeam, {
        id: teamMemberId.value
      });

      let newItem = _.filter(listOfTeams,
        {
          id: selectedTeamForMember
        }
      );

      newItem[0].usersInTeam.push(member[0]);
      //update table
      let row = teamMembersTableItems.filter(t => t.id === teamMemberId.value);
      row[0].team = newItem[0].name;
      row[0].teamId = newItem[0].id;

      setTeamMembersTableItems(teamMembersTableItems);


      setListOfTeams([...listOfTeams])
      const SuccessMsg = () => (
        <div>
          <div>Member updated successfully.</div>
        </div>
      )
      toast.success(<SuccessMsg />)
    }


  }
  const handleCreateTeamMemberClick = async () => {

    let res = await upsertUserMemnder();
    resetTeamMembderFields()
    //refresh after team created

    let currentTeam = listOfTeams.filter(t => t.id === selectedTeamForMember);
    currentTeam[0].usersInTeam.push({
      id: teamMemberId.value, name: teamMemberName
    })
    initMembersInTeams(listOfTeams)

    const SuccessMsg = () => (
      <div>
        <div>New team member was created successfully.</div>
      </div>
    )
    toast.success(<SuccessMsg />)

  }
  const handleTableTeamMembersSelect = (e) => {



    if (teamMembersTableItems[e].id === selectedTeamMember.id) {
      setTeamMemberId(null)
      setSelectedTeamMember({})
      setTeamMemberRO(false)
    }
    else {

      setTeamMemberRO(true)
      setTeamMemberId({ label: teamMembersTableItems[e].memberName, value: teamMembersTableItems[e].id })
      let team = listOfTeams.filter(team => team.id === teamMembersTableItems[e].teamId)

      setSelectedTeamForMember(team[0].id)
      setSelectedTeamMember(teamMembersTableItems[e])
    }
  }

  const handleTableDeptSelect = (e) => {

    if (deptTableItems[e].id === selectedDept.id) {

      inputDeptNameRef.current.value = "";
      inputdescriptionDeptRef.current.value = "";
      if (deptTableItems[e].parentDepartmentId != "")
        setSelectedDeperatment('')
      if (deptTableItems[e].managerName != "")
        setDeptManagerName(null)
      inputSlackDeptRef.current.value = "";

      setSelectedDept({})
    }
    else {
      inputDeptNameRef.current.value = deptTableItems[e].name
      inputdescriptionDeptRef.current.value = deptTableItems[e].description
      if (deptTableItems[e].parentDepartmentId != "")
        setSelectedDeperatment(deptTableItems[e].parentDepartmentId)
      if (deptTableItems[e].managerName != "")
        setDeptManagerName({ label: deptTableItems[e].managerName, value: deptTableItems[e].managerUserId })
      inputSlackDeptRef.current.value = deptTableItems[e].slackChannel
      setSelectedDept(deptTableItems[e])
    }
  }

  const handleTableTeamSelect = (e) => {

    if (teamTableItems[e].id === selectedTeam.id) {

      inputTeamNameRef.current.value = "";
      inputDescriptionRef.current.value = "";
      inputSlackRef.current.value = "";
      if (teamTableItems[e].departmentId != "")
        setSelectedDeperatmentFromTeam("")
      if (teamTableItems[e].managerName != "")
        setTeamManagerName(null)
      setSelectedTeam({})
      setTeamLeader("")

    }
    else {

      inputTeamNameRef.current.value = teamTableItems[e].name
      inputDescriptionRef.current.value = teamTableItems[e].description
      inputSlackRef.current.value = teamTableItems[e].slackChannel
      if (teamTableItems[e].departmentId != "")
        setSelectedDeperatmentFromTeam(teamTableItems[e].departmentId)
      if (teamTableItems[e].managerName != "")
        setTeamManagerName({ label: teamTableItems[e].managerName, value: teamTableItems[e].managerUserId })
      setTeamLeader(teamTableItems[e].managerUserId)
      setSelectedTeam(teamTableItems[e])
    }
  }



  const handleTableTeamRemove = (e) => {
    const url = consts.requests.deleteTeam.toString().replace('{teamId}', teamTableItems[e].id);
    return instance.delete(url,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        if (res?.status === 200) {
     

          await getManageTeams()
          const SuccessMsg = () => (
            <div>
              <div>Team was removed successfully.</div>
            </div>
          )
          toast.success(<SuccessMsg />)
        }
      }).catch(err => {

        console.log(err);
      });
  }


  const [regexInValid, setRegexInValid] = useState(false)
  const handleValidation = (e) => {
    const reg = new RegExp("^[a-z0-9A-Z._-]+$");
    setRegexInValid(!reg.test(e.target.value));
  };

  const handleTabChange = (event, newValue) => {

    setTabsValue(newValue);
   

    if (newValue === "2") {
      resetTeamFields()
    }
    if (newValue === "3") {
      resetTeamMembderFields()

    }
    if (newValue === "1") {
      resetDeptFields()
    }
    if (newValue === "0") {
      resetUserFields();
    }
  }

  return (
    <div>
      <div className="sc-header">
        <SCTop displaySecondLine={false} displayFilter={false} />
      </div>
      <div className="sc-main">

        <Box sx={{ width: '100%', typography: 'body1' }} style={{ paddingLeft: '10px' }}>
          <TabContext value={tabsValue} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab1 className="sc-tab" label="Create User" value="0" />
                <Tab1 className="sc-tab" label="Create Department" value="1" />
                <Tab1 className="sc-tab" label="Create Team" value="2" />
                <Tab1 className="sc-tab" label="Manage Team's Members" value="3" />
              </TabList>
            </Box>
            <TabPanel value="0">
              <div className='create-user-container'>
                <div className='mu-table'>
                  <SCTable title="Users" columnsItems={columnsItems} rowItems={userTableItems}
                    clearCheckBoxLists={clearCheckBoxUsersLists}
                    allCheckBoxsNull={setIsCheckBoxsNull} handleRemove={(event) => handleTableUserRemove(event)} handleSelect={(event) => handleTableUserSelect(event)}
                    includesRemoveButton={true} includesSelectButton={true} />
                </div>
                <div className='mu-form'>
                  <div className="manage-user-field">
                    <SCInputText size="small" className="md-input"
                      label="Full Name" refName={inputUserRef}
                    /></div>
                  <div className="manage-user-field">
                    <SCInputText size="small" className="md-input"
                      label="Email" refName={inputEmailRef}
                    /></div>
                  <div>
                    <div className="manage-user-field">
                      <SCInputText size="small" className="md-input"
                        label="Email Takeaway" refName={inputEmailTakeawayRef}
                      /></div>
                    <div>
                      <div className="manage-user-field">
                        <SCDropDownList className="md-ddl-edit" label="User Role" componentState={selectedRole} setComponentState={setSelectedRole}
                          size="1000" items={userRoles} handleChange={(e) => handleChangeRole(e)} value={selectedRole} />
                      </div>
                      <div>
                        {
                          Object.keys(selectedUser).length === 0 ?
                            (
                              <SCButton size="medium" text="Create User" handleButtonClick={handleCreateUserClick} />
                            ) :
                            (

                              <SCButton size="medium" text="Update User" handleButtonClick={handleTableUserUpdate} />
                            )
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="1">

              <div className='mu-table'>
                <SCTable title="Department" columnsItems={columnsItems} rowItems={deptTableItems}

                  allCheckBoxsNull={setIsCheckBoxsDeptsNull} handleRemove={(e) => handleTableDeptRemove(e)} handleSelect={handleTableDeptSelect}
                  includesRemoveButton={true} includesSelectButton={true} />
              </div>
              <div className='mu-form'>
                <div className='create-department-container'>
                  <div className="manage-user-field">
                    <SCInputText size="small" className="md-input"
                      label="Name" refName={inputDeptNameRef}
                    />
                  </div>
                  <div className="manage-user-field">
                    <SCInputText size="small" className="md-input"
                      label="Description" refName={inputdescriptionDeptRef}
                    />
                  </div>

                  <div className="manage-user-field">
                    <UserAutoComplete inputValue={deptManagerName} handleAutoUserClick={handleAutoDeptManagerClick} inputName="Department Manager Name" />
                  </div>
                  <div className="">
                    If this department related to parent department (sub-department) please choose the parent department
                  </div>
                  <SCDropDownList className="md-ddl-edit" label="Parent Department" componentState={selectedDeperatment} setComponentState={setSelectedDeperatment}
                    size="1000" items={ddlDeperatment} handleChange={(e) => handleChangeDeperatment(e)} value={selectedDeperatment} />
                  <div className="manage-user-field">
                    <SCInputText size="small" className="md-input"
                      label="Slack Channel" refName={inputSlackDeptRef}
                    /></div>
                  <div>
                    <div className="manage-user-field">
                      {
                        selectedDept.length === 0 ?
                          (                            
                            <SCButton size="medium" customClass="button-create-department" text="Create Department" handleButtonClick={handleCreateDepartmentClick} />
                          ) :
                          (
                            <SCButton size="medium" customClass="button-create-department" text="Update Department" handleButtonClick={handleTableDeptUpdate} />
                          )
                      }

                    </div>
                  </div>
                </div>
              </div>



            </TabPanel>
            <TabPanel value="2">
              <div className='mu-table'>
                <SCTable title="Teams" columnsItems={columnsItems} rowItems={teamTableItems}
                  allCheckBoxsNull={setIsCheckBoxsTeamsNull}
                  handleRemove={(event) => handleTableTeamRemove(event)} handleSelect={(event) => handleTableTeamSelect(event)}
                  includesRemoveButton={true} includesSelectButton={true} />
              </div>
              <div className='mu-form'>
                <div className='create-team-container'>

                  <div className="manage-user-field">
                    <SCInputText size="small" className="md-input"
                      label="Team Name" invalid={regexInValid} handleInputValueChange={(e) => handleValidation(e)} regex={"^[a-z0-9A-Z._-]+$"} refName={inputTeamNameRef}
                    /></div>
                  <div className="manage-user-field">
                    <SCInputText size="small" className="md-input"
                      label="Description" refName={inputDescriptionRef}
                    /></div>
                  <div className="manage-user-field">
                    <UserAutoComplete inputValue={teamManagerName} handleAutoUserClick={handleAutoTeamLeadClick} inputName="Team Leader" />
                  </div>
                  <div className="manage-user-field">
                    <SCDropDownList className="md-ddl-edit md-input" label="Department" componentState={selectedDeperatmentFromTeam} setComponentState={setSelectedDeperatmentFromTeam}
                      size="1000" items={ddlDeperatment} handleChange={(e) => handleChangeDeperatmentFromTeam(e)} value={selectedDeperatmentFromTeam} />
                  </div>
                  <div className="manage-user-field">
                    <SCInputText size="small" className="md-input"
                      label="Slack Channel" refName={inputSlackRef}
                    /></div>
                  <div>
                    {
                      selectedTeam.length === 0 ?
                        (
                          <SCButton size="medium" text="Create Team" handleButtonClick={handleCreateTeamClick} />
                        ) :
                        (

                          <SCButton size="medium" text="Update Team" handleButtonClick={handleTableTeamUpdate} />
                        )
                    }

                  </div>
                </div>
              </div>

            </TabPanel>
            <TabPanel value='3'>
              <div className='mu-table'>
                <SCTable title="Members" columnsItems={columnsMembersItems} rowItems={teamMembersTableItems}
                  allCheckBoxsNull={setIsCheckBoxsTeamsMembersNull}
                  handleRemove={(event) => handleTableTeamMembersRemove(event)} handleSelect={(event) => handleTableTeamMembersSelect(event)}
                  includesRemoveButton={true} includesSelectButton={true} />
              </div>
              <div className='mu-form'>
                <div className="manage-user-field">
                  <SCDropDownList className="md-ddl-edit" label="Team" componentState={selectedTeamForMember} setComponentState={setSelectedTeamForMember}
                    size="1000" items={ddlTeams} handleChange={(e) => handleChangeTeamForMember(e)} value={selectedTeamForMember} />
                </div>
                <div className="manage-user-field">
                  <UserAutoComplete readOnly={teamMemberRO} inputValue={teamMemberId} handleAutoUserClick={handleAutoTeamMemberClick} inputName="Team Member Name" />
                </div>
                <div>
                  {
                    selectedTeamMember.length === 0 ?
                      (
                        <SCButton customClass="manage-team-member-btn" size="medium" text="Create Team Member" handleButtonClick={handleCreateTeamMemberClick} />
                      ) :
                      (

                        <SCButton customClass="manage-team-member-btn" size="medium" text="Update Team Member" handleButtonClick={handleUpdateTeamMemberClick} />
                      )
                  }

                </div>
              </div>
            </TabPanel>

          </TabContext>
        </Box>




      </div>
    </div>)
}

export default ManageUsers