import React, { useEffect, useState, useContext } from 'react'
import './SAAnalyzerRootCause.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const SAAnalyzerRootCause = ({ services, values, displayAsTabs = true }) => {


    const [rootCause, setRootCause] = useState({})
    const [databaseChanges, setDatabaseChanges] = useState({})
    const [insights, setInsights] = useState({})
    const [value, setValue] = React.useState("0");


    useEffect(() => {





        if (values && Object.entries(values).length > 0) {
            if (values?.rootCause && Object.entries(values?.rootCause).length > 0) {
                setRootCause(values?.rootCause)
                setDatabaseChanges(values?.rootCause?.databaseChanges)
            }

            setInsights(values.insights)
        }
    }, [values])

    const TranslateAnalyzerLogId = (logId) => {
        console.log("**logId**", logId)
        //long and short term
        //loop on service => eact element get 'report' => loop on report, search for "error_count".data.errorLogs and find the analyzerLogId
        // return the error message
        let foundErrorLog = null;
        let logs = services[0].report.filter(r => r.code === "error_new_ratio");

        for (let obj of logs) {
            if (obj.data.errorLogs && Array.isArray(obj.data.errorLogs)) {
                // Use .find() to search for the desired item
                foundErrorLog = obj.data.errorLogs.find(errorLog => errorLog.analyzerLogId === logId);
                // Check if the item is found
                if (foundErrorLog) {
                    break; // Exit from the loop
                }
            }
        }
        console.log("**foundErrorLog**", foundErrorLog)

        if (foundErrorLog) {
            return (
                <div>
                    <div>logger: {foundErrorLog.logger}</div>
                    <div>Message: {foundErrorLog.message}</div>
                </div>
            )
        }

        console.log("services", services)
        return (
            <span>No logs were found.</span>
        )
    }

    const translateInsightId = (id) => {
        if (services.length === 0) {
            return
        }
        const result = services
            .map(service => service?.report?.find(report => report.id === id))
            .find(report => report !== undefined);
        return result?.module ? result?.module : ""
    }

    const calcScore = (score) => {
        console.log(services)
        if (!score) {
            return 0;
        }
        return (score * 100).toFixed(2);
    }

    const RenderCodeChanges = ({ value }) => {
        console.log('RenderRootCause', value)

        if (value === null || !value.matchedCodeChanges || value.matchedCodeChanges.length === 0) {
            return (
                displayAsTabs === true ?
                    (
                        <span>There is no details regarding code changes</span>
                    ) :
                    (
                        <span></span>
                    )

            )
        }

        return (
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/*<TableCell>ANALYZER LOG</TableCell>*/}
                                <TableCell>QUERY</TableCell>
                                <TableCell>INSIGHT MODULE</TableCell>
                                <TableCell>SCORE</TableCell>
                                <TableCell>TEXT</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                value.matchedCodeChanges.map((m, index) => (
                                    <TableRow key={`sa-root-cause-table-row` + index}>
                                        {/*
                                        <TableCell>{TranslateAnalyzerLogId(m.analyzerLogId)}</TableCell>
                                        */}
                                        <TableCell>{m.query}</TableCell>
                                        <TableCell>{translateInsightId(m.insightId)}</TableCell>
                                        <TableCell>{calcScore(m.score)}</TableCell>
                                        <TableCell>
                                            <SyntaxHighlighter language="csharp" style={darcula}>
                                                {m.text}
                                            </SyntaxHighlighter>
                                        </TableCell>

                                    </TableRow>
                                ))

                            }
                        </TableBody>
                    </Table>
                </TableContainer>



            </React.Fragment>
        )
    }


    const RenderRootCause = ({ value }) => {

        console.log('RenderRootCause', value)

        if (value && value.length > 0) {
            let modules = '';
            for (let i = 0; i < value.length; i++) {
                if (value.length === i + 1) {
                    modules += value[i].module
                }
                else {
                    modules += value[i].module + ', '
                }

            }
            let cause = value.length === 1 ? 'cause: ' : 'causes: ';
            let text = 'The AI mechanism was detected ' + value.length + ' major root ' + cause + modules;

            return (
                <div colSpan={5}>
                    {text}
                </div>
            )
        }
        return (
            <div colSpan={5}>

            </div>
        )

    }

    const RenderClassifications = ({ value, type }) => {


        console.log(value, type)

        if (value === null) {
            return (
                displayAsTabs === true ?
                    (
                        <TableRow>
                            <TableCell>Sorry, we don't have any classifications insights for you</TableCell>
                        </TableRow>
                    ):
                    (
                        <span></span>
                    )
                
            )
        }
        return (

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className='sa-root-cause-table-type'>ANALYZER LOG</TableCell>
                            <TableCell className='sa-root-cause-table-insight-id'>INSIGHT</TableCell>
                            <TableCell className='sa-root-cause-table-module'>EXPLANATION, MODULE AND SCORE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            value?.map((v, index) => (
                                <TableRow key={`sa-root-cause-table-row` + index}>
                                    <TableCell className='sa-root-cause-table-type' key={`sa-root-cause-table-type` + index}>{TranslateAnalyzerLogId(v.analyzerLogId)}</TableCell>
                                    <TableCell className='sa-root-cause-table-insight-id' key={`sa-root-cause-table-insight-id` + index}>{getInsightByValue(v.insightId)}</TableCell>
                                    <TableCell className='sa-root-cause-table-module' key={`sa-root-cause-table-module` + index}>{getAIExplanation(v.classifiedModules)}</TableCell>
                                </TableRow>
                            ))

                        }
                    </TableBody>
                </Table>
            </TableContainer>

        )



    }

    const RenderDatabaseChanges = () => {
        console.log("databaseChanges", databaseChanges)

        if (databaseChanges && databaseChanges.length > 0) {
            return (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ANALYZER LOG</TableCell>
                                <TableCell>INSIGHT</TableCell>
                                <TableCell>SCORE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                databaseChanges.map((db, index) => (
                                    <TableRow key={`sa-root-cause-table-row` + index}>
                                        <TableCell key={`sa-root-cause-table-type` + index}>{TranslateAnalyzerLogId(db.analyzerLogId)}</TableCell>
                                        <TableCell key={`sa-root-cause-table-insight-id` + index}>
                                            <div>{getInsightAndDetailsByValue(db.databaseInsightId)}</div>
                                            <div>{getInsightAndDetailsByValue(db.logInsightId)}</div>
                                        </TableCell>
                                        <TableCell key={`sa-root-cause-table-module` + index}>{db.score}</TableCell>
                                    </TableRow>
                                ))

                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        return (
            displayAsTabs === true ?
                (
                    <TableRow>
                        <TableCell>Sorry, we don't have any database changes for you</TableCell>
                    </TableRow>
                ) :
                (
                    <span></span>
                )

        )
    }

    const getAIExplanation = (values) => {
        return (

            values.map((v) => (
                <div>
                    <div>{v.module}: {v.explanation}</div>
                    <div>Score: {v.score}</div>
                </div>
            ))

        )
    }


    const getInsightByValue = (id) => {
        if (id !== null) {
            let insight = insights.find(i => i.id === id);
            if (insight) {
                return insight.module;
            }
        }


    }

    const getInsightAndDetailsByValue = (id) => {
        if (id !== null) {
            let insight = insights.find(i => i.id === id);
            if (insight) {
                let text = insight.module + "(" + insight.code + ")";
                return text;
            }
            return "Insight was not found."
        }


    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            {
                displayAsTabs === true ?
                    (
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Root Cause" value="0" />
                                    <Tab label="Code Changes" value="1" />
                                    <Tab label="Classification" value="2" />
                                    <Tab label="Database Changes" value="3" />
                                </TabList>
                            </Box>
                            {
                                Object.entries(rootCause).map(([key, value], index) => (
                                    key === 'rootCauses' ?
                                        (
                                            <TabPanel value="0"><RenderRootCause value={value} /></TabPanel>
                                        ) : key === 'codeChanges' ?
                                            (
                                                <TabPanel value="1"><RenderCodeChanges value={value} /></TabPanel>
                                            ) : key === 'classifications' ?
                                                (
                                                    <TabPanel value="2"><RenderClassifications value={value} /></TabPanel>
                                                ) : key === 'databaseChanges' ?
                                                    (
                                                        <TabPanel value="3"><RenderDatabaseChanges /></TabPanel>
                                                    ) :
                                                    (<span></span>)


                                ))
                            }


                        </TabContext>
                    ) :
                    (

                        Object.entries(rootCause).map(([key, value], index) => (
                            key === 'rootCauses' ?
                                (
                                    <RenderRootCause value={value} />

                                ) : key === 'codeChanges' ?
                                    (
                                        <React.Fragment>
                                            <div className='pdf-data-incident-secion-title'>Code Changes</div>
                                            <RenderCodeChanges value={value} />
                                        </React.Fragment>

                                    ) : key === 'classifications' ?
                                        (
                                            <React.Fragment>
                                                <div className='pdf-data-incident-secion-title'>Classifications</div>
                                                <RenderClassifications value={value} />
                                            </React.Fragment>
                                        ) : key === 'databaseChanges' ?
                                            (
                                                <React.Fragment>
                                                    <div className='pdf-data-incident-secion-title'>Database Changes</div>
                                                    <RenderDatabaseChanges />
                                                </React.Fragment>
                                            ) :
                                            (<span></span>)


                        ))


                    )
            }



        </React.Fragment>
    )
}

export default SAAnalyzerRootCause