import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import DataContext from "../Context/DataContext";
import { useContext, useState, useEffect } from "react"
import './AlertModal.css';


const AlertModal = ({ text, type }) => { //info = 1 // error - 2
  const { modalAlertShow, setModalAlertShow } =
    useContext(DataContext);

  const [countDown, setCountDown] = useState();
  const [intervalId, setIntervalId] = useState(0);



  const handleCloseModal = () => {    
    setModalAlertShow(false)

  }

  const handleModalShow = () => {
    
    setModalAlertShow(true)
    setCountDown(10)
  }



  useEffect(() => {
    
    if (modalAlertShow) {

      let c = 10;
      const id = setInterval(() => {
        
        c = c - 1;
        setCountDown(c)
        
        if (c === 0) {       
          setModalAlertShow(false)
        }
      }, 1000);

      // Store the interval ID in state
      setIntervalId(id);


    }
    if(!modalAlertShow){
    
      clearInterval(intervalId);
      setIntervalId(null);
      setCountDown(10)
    }


  }, [modalAlertShow])

 


  return (
    <div>
      {
        type === 1 ?
          (
            <Modal
              show={modalAlertShow}
              onHide={handleCloseModal}
              onShow={handleModalShow}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="alert-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="alert-modal-title">
                  Your new repository in on the way &#128512;
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>{text}</div>

              </Modal.Body>
              <Modal.Footer className="modal-alert-footer">
                <div className="auto-close-text">This alert will automatically close in a {countDown} sec.</div>
              </Modal.Footer>
            </Modal>
          ) : type === 2 ?
            (
              <Modal
                show={modalAlertShow}
                onHide={handleCloseModal}
                onShow={handleModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="alert-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter" className="alert-modal-title-error">
                    Something went wrong &#128532;
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>{text}</div>

                </Modal.Body>
                <Modal.Footer className="modal-alert-footer">
                  <div className="auto-close-text-error">This alert will automatically close in a {countDown} sec.</div>
                </Modal.Footer>
              </Modal>
            ) : type === 3 ?
              (
                <Modal
                  show={modalAlertShow}
                  onHide={handleCloseModal}
                  onShow={handleModalShow}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="alert-modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="alert-modal-title">
                      We're still in process &#128075;
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>{text}</div>

                  </Modal.Body>
                  <Modal.Footer className="modal-alert-footer">
                    <div className="auto-close-text">This alert will automatically close in a {countDown} sec.</div>
                  </Modal.Footer>
                </Modal>
              ) :
              (
                <span></span>
              )
      }
    </div>

  )
}

export default AlertModal
