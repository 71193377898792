import { useEffect, useState, useContext } from "react";
import * as React from 'react';
import './Insights.css';
import SCTop from "../Components/SCTop";
import DataContext from "../Context/DataContext";
import _, { forEach } from "lodash";
import SCDropDownList from "../Components/SCDropDownList";
import { Chart, registerables } from 'chart.js';
import SCLine from "../Components/SCLine";
import SelfServiceSmallTable from "../Components/SmallTables/SelfServiceSmallTable";
import PermissionSmallTable from "../Components/SmallTables/PermissionSmallTable";
import SCContainer from "../Components/SCContainer";
Chart.register(...registerables);




const Insights = () => {
    const { servicesCached, insights, setCurrentPage, requestCICDItems, userPermissionRequests } = useContext(DataContext);

    const [ddlRepos, setDdlRepos] = useState([])
    const [selectedRepo, setSelectedRepo] = useState('All');
    const [techStackChartLabels, setTechStackChartLabels] = useState([]);
    const [techStackChartData, setTechStackChartData] = useState([]);
    const [techServices, setTechServices] = useState([])
    const [chart, setChart] = useState({})
    const [techChartHeight, setTechChartHeight] = useState('100')
    const [techChartWidth, setTechChartWidth] = useState('80')
    const [techChartStepSize, setTechChartStepSize] = useState(5)




    const backgroundColor = [
        'rgba(255,0,0,0.62)', //red
        'rgba(27,146,241,0.62)', //blue
        'rgba(255,231,80,0.6)', // yellow
        'rgba(0,153,0,0.65)' // green

    ]
    const borderColor = [
        'rgba(255,0,0,0.62)', //red
        'rgba(27,146,241,0.62)', //blue
        'rgba(255,231,80,0.6)', // yellow
        'rgba(0,153,0,0.65)' // green
    ]
    const borderWidth = 1

    useEffect(() => {
        setCurrentPage('home')


    }, [])


    useEffect(() => {
        if (servicesCached && servicesCached.length > 0) {
            //  generateServicesStatusChart();
            generateNumberOfServicesChart();

        }



    }, [servicesCached])

    useEffect(() => {

        if (insights && Object.entries(insights).length > 0) {

            generateBuildsChart();
            generateReleasesChart();
            mostUsedInfraPackages();
            techStackChart();
        }



    }, [insights])

    /* const generateServicesStatusChart = () => {
         var labels = [];
         var data = [];
 
         let groupedServices = _.groupBy(servicesCached, 'serviceStatus')
 
         for (var [key, value] of Object.entries(groupedServices)) {
 
             if (key === "null") {
                 key = 'Status Undefined';
             }
             if (key === 'HasPackagesConflict') {
                 key = 'Package Conflicts';
             }
             labels.push(value.length + ' ' + key);
             data.push(value.length);
         }
 
         const ctx = document.getElementById('servicesStatusChart');
         const myChart = new Chart(ctx, {
             type: 'pie',
             data: {
                 labels: labels,
                 datasets: [{
                     label: 'Number of services with # status',
                     data: data,
                     backgroundColor: ['rgba(0,153,0,0.65)', 'rgba(27,146,241,0.62)', 'rgba(255,231,80,0.6)'],
                     borderColor: ['rgba(0,153,0,0.65)', 'rgba(27,146,241,0.62)', 'rgba(255,231,80,0.6)'],
                     borderWidth: borderWidth
                 }]
             },
             options: {
                 plugins: {
                     title: {
                         display: true,
                         text: 'NUMBER OF SERVICES BY STATUS'
                     }
                 }
             }
         });
 
 
 
     }
 */

    const generateNumberOfServicesChart = () => {
        var labels = [];
        var data = [];

        let groupedServices = _.groupBy(servicesCached, 'serviceType')
        for (const [key, value] of Object.entries(groupedServices)) {

            labels.push(key);
            data.push(value.length);
        }

        let existingChart = Chart.getChart('resourceTypeChart');
        if (existingChart) {
            existingChart.destroy();
        }
        

        const ctx = document.getElementById('resourceTypeChart');
        const myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Number of services by resources',
                    data: data,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: borderWidth
                }]
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'NUMBER OF SERVICES BY RESOURCES'
                    }
                }
            }
        });
    }


    const generateBuildsChart = () => {
        var labels = [];
        var data = [];


        labels.push(insights.successBuildCount + ' successful builds');
        labels.push(insights.failedBuildCount + ' failed builds');

        data.push(insights.successBuildCount);
        data.push(insights.failedBuildCount);

        var total = parseInt(insights.successBuildCount) + parseInt(insights.failedBuildCount)

        let existingChart = Chart.getChart('resourceBuildsChart');
        if (existingChart) {
            existingChart.destroy();
        }

        const ctx = document.getElementById('resourceBuildsChart');
        const myChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Number of builds',
                    data: data,
                    backgroundColor: ['rgba(0,153,0,0.65)', 'rgba(255,0,0,0.62)'],
                    borderColor: ['rgba(0,153,0,0.65)', 'rgba(255,0,0,0.62)'],
                    borderWidth: borderWidth
                }]
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'NUMBER OF BUILDS (LAST 30 DAYS) - TOTAL: ' + total
                    }
                }
            }
        });

    }

    const generateReleasesChart = () => {
        var labels = [];
        var data = [];


        labels.push(insights.successReleaseCount + ' successful releases');
        labels.push(insights.failedReleaseCount + ' failed releases');


        data.push(insights.successReleaseCount);
        data.push(insights.failedReleaseCount);


        var total = parseInt(insights.successReleaseCount) + parseInt(insights.failedReleaseCount)

        let existingChart = Chart.getChart('resourceReleasesChart');
        if (existingChart) {
            existingChart.destroy();
        }


        const ctx = document.getElementById('resourceReleasesChart');
        const myChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Number of Releases',
                    data: data,
                    backgroundColor: ['rgba(0,153,0,0.65)', 'rgba(255,0,0,0.62)'],
                    borderColor: ['rgba(0,153,0,0.65)', 'rgba(255,0,0,0.62)'],
                    borderWidth: borderWidth
                }]
            },
            options: {

                plugins: {
                    title: {
                        display: true,
                        text: 'NUMBER OF RELEASES (LAST 30 DAYS) - TOTAL: ' + total
                    }
                }
            }
        });

    }

    const mostUsedInfraPackages = () => {
        var labels = [];
        var data = [];
        var total = 0;
        insights?.mostUsedInfraPackages?.forEach(pkg => {
            labels.push(pkg.service);
            data.push(pkg.number);
            total = total + parseInt(pkg.number);
        });



        /* const ctx = document.getElementById('resourceMostUsedPackagesChart');
         const myChart = new Chart(ctx, {
             type: 'bar',
             data: {
                 labels: labels,
                 datasets: [{
                     label: 'usage per package',
                     data: data,
                     backgroundColor: ['rgba(27,146,241,0.62)'],
                     borderColor: ['rgba(27,146,241,0.62)'],
                     borderWidth: borderWidth
                 }]
             },
             options: {
                 indexAxis: 'y',
                 plugins: {
                     title: {
                         display: true,
                         text: 'USAGE PER PACKAGE - TOTAL: ' + total
                     },
                     legend: {
                         display: false
                     },
                 },
                 scales: {
                     y: {
                         beginAtZero: true
                     }
                 }
             }
         });*/
    }

    const techStackChart = () => {

        var techServices = [];

        var groupByServiceName = _.groupBy(insights?.techStackWithServices, "serviceInfoName");
        var count = 0;
        Object.entries(groupByServiceName).forEach(([key, value]) => {
            count++;
            const total = _.sumBy(value, v => {
                return v.count;
            });
            techServices.push({ id: count, repoName: key, projectsInRepo: value, totalProjects: total })

        })
        var repoList = [];
        repoList.push({ name: 'All', value: 'All' })
        var orderedRepoName = _.orderBy(techServices, ['repoName'], ['asc']);
        orderedRepoName.forEach(v => {

            repoList.push({ name: v.repoName, value: v.id });

        });

        setDdlRepos([...repoList]);
        setTechServices(techServices);

    }
    const handleChange = (e) => {
        setSelectedRepo(e.target.value);
    }
    useEffect(() => {

        if (selectedRepo !== 'All') {
            var labels = [];
            var data = [];

            var selectedRepository = techServices.filter(t => t.id === selectedRepo)

            if (selectedRepository && selectedRepository.length > 0) {

                selectedRepository[0]?.projectsInRepo?.forEach(item => {
                    labels.push(item.netVersion)
                    data.push(item.count);
                });
            }
            setTechStackChartLabels([...labels])
            setTechStackChartData([...data])

            if (data.length === 1) {
                setTechChartHeight('14')

            }

            if (data.length > 1 && data.length <= 3) {
                setTechChartHeight('17')
            }

            if (data.length > 3 && data.length <= 5) {
                setTechChartHeight('20')
            }

            if (data.length > 5 && data.length <= 10) {
                setTechChartHeight('40')
            }
            if (data.length > 10 && data.length <= 20) {
                setTechChartHeight('85')
            }

            if (data.length > 20) {
                setTechChartHeight('100')
            }

            let max_val = _.max(data);


            if (max_val > 0 && max_val <= 5) {
                setTechChartStepSize(1)

            }
            if (max_val > 5 && max_val <= 30) {
                setTechChartStepSize(2)

            }
            if (max_val > 30 && max_val <= 80) {
                setTechChartStepSize(5)

            }
            if (max_val > 80) {
                setTechChartStepSize(10)
            }

        }
        else {
            techStackChart();
            setTechChartHeight('100')
            setTechChartStepSize(5)
        }



    }, [selectedRepo])

    const techStackChartSetup = () => {
        return {
            labels: techStackChartLabels,
            datasets: [{
                label: 'Number of projects that are implemented by # framework',
                data: techStackChartData,
                backgroundColor: ['rgba(27,146,241,0.62)'],
                borderColor: ['rgba(27,146,241,0.62)'],
                borderWidth: borderWidth
            }]
        }
    }

    const techStackChartConfig = () => {
        return {
            type: 'bar',
            data: techStackChartSetup(),
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                },
                indexAxis: 'y',
                plugins: {
                    title: {
                        display: true,
                        text: 'NUMBER OF PROJECTS THAT ARE IMPLEMENTED BY # FRAMEWORK'
                    },
                    legend: {
                        display: false
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            // forces step size to be 50 units
                            stepSize: techChartStepSize
                        }
                    }
                }
            }
        }
    }
    const techStackChartDestroy = (chart) => {
        chart.destroy();
    }

    /* const renderTechChart = () => {
         if (Object.entries(chart).length > 0) {
             techStackChartDestroy(chart)
         }
         let myChart = new Chart(document.getElementById('techStackChart'),
             techStackChartConfig()
         )
         setChart(myChart)
     }*/

    /* useEffect(() => {
 
         renderTechChart();
 
     }, [techStackChartLabels, techStackChartData])*/

    useEffect(() => {
        var labels = [];


        var groupByTechStack = _.groupBy(insights?.techStackWithServices, "netVersion");

        Object.entries(groupByTechStack).forEach(([key, value]) => {
            labels.push(key);
        })

        splitFrameworks(labels)
    }, [techServices])

    const splitFrameworks = (labels) => {
        if (labels && labels.length > 0) {

            var complexTechString = labels.filter(item => item.indexOf(';') > -1);
            createSimpleFrameworkNames(complexTechString)

            complexTechString = labels.filter(item => item.indexOf(';') === -1);
            createSimpleFrameworkNames(complexTechString)


            var disc = _.uniqBy(arrFlatData, (e) => {
                return e.name;
            });




            techServices.forEach(ts => {
                ts?.projectsInRepo.forEach(p => {

                    if (p.netVersion) {
                        if (p.netVersion.indexOf(';') > -1) {
                            var splitTech = p.netVersion.split(';');
                            splitTech.forEach(s => {
                                var entity = disc.filter(d => d.originalName === s)
                                if (entity && entity.length > 0) {
                                    entity[0].count.push(p.count)
                                }
                            });

                        }
                        else {
                            var entity = disc.filter(d => d.originalName === p.netVersion)
                            if (entity && entity.length > 0) {
                                entity[0].count.push(p.count)
                            }
                        }

                    }
                });
            });


            disc.forEach(d => {
                var total = _.sum(d.count)
                d.total = total
            });



            var chartLabels = [];
            var chartData = [];
            //build chart labels & data
            disc.forEach(element => {
                chartLabels.push(element.originalName);
                chartData.push(element.total);
            });
            setTechStackChartLabels([...chartLabels])
            setTechStackChartData([...chartData])



        }
    }
    var arrFlatData = [];
    const createSimpleFrameworkNames = (complexTechString) => {


        complexTechString.forEach(element => {

            var ele = element;
            if (element.indexOf(';') > -1) {
                ele = element.split(';');

                ele.forEach(e => {

                    arrFlatData.push({ name: e, originalName: e, count: [], total: 0 });
                });

            }
            else {

                arrFlatData.push({ name: ele, originalName: ele, count: [], total: 0 });
            }


        });

    }


    return (
        <React.Fragment>
            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>

            <div className="in-main">
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Services</div>
                        <div className="in-chart-card-details-value">{insights.serviceCount}</div>
                    </div>
                </div>
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Projects</div>
                        <div className="in-chart-card-details-value">{insights.projectCount}</div>
                    </div>
                </div>
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Repositories</div>
                        <div className="in-chart-card-details-value">{insights.repositoryCount}</div>
                    </div>
                </div>
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Teams</div>
                        <div className="in-chart-card-details-value">{insights.teamCount}</div>
                    </div>
                </div>
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Infra Packages</div>
                        <div className="in-chart-card-details-value">{insights.infraPackageCount}</div>
                    </div>
                </div>

                <div className="in-tables-ss-permission">
                    <div className="in-table-container">
                        <div className="in-table-selfservice">
                            <SCContainer children={<SelfServiceSmallTable />} loading={requestCICDItems.status} />

                        </div>

                        <div className="in-table-permission">
                            <SCContainer children={<PermissionSmallTable />} loading={userPermissionRequests.status} />

                        </div>
                    </div>
                </div>

                <div className="in-chart-small services-status-chart" >
                    <canvas id="resourceReleasesChart" style={{ maxWidth: '300px', maxHeight: '300px', margin: 'auto' }}></canvas>
                </div>

                <div className="in-chart-small services-status-chart" >
                    <canvas id="resourceBuildsChart" style={{ maxWidth: '300px', maxHeight: '300px', margin: 'auto' }}></canvas>
                </div>

                {/*
                <div className="in-chart-small services-status-chart services-status-chart" >
                <canvas id="servicesStatusChart" style={{ maxWidth: '300px', maxHeight: '300px' }}></canvas>
                 </div>
                */}

                <div className="in-chart-small resource-type-chart services-status-chart"  >
                    <canvas id="resourceTypeChart" style={{ maxWidth: '300px', height: '300px', margin: 'auto' }}></canvas>
                </div>

                {/*
                <div className="in-chart-medium services-tech-stack-chart" >
                <SCDropDownList wraperClassName="in-ddl-wraper" className="in-md-ddl" label="Select repo" componentState={selectedRepo} setComponentState={setSelectedRepo}
                    size="small" items={ddlRepos} handleChange={(e) => handleChange(e)} value={selectedRepo} />
                    <canvas id="techStackChart" style={{ maxWidth: techChartWidth + 'vw', maxHeight: techChartHeight + 'vh' }}></canvas>
                  </div>

                    <div className="in-chart-medium services-status-chart" >
                        <canvas id="resourceMostUsedPackagesChart" ></canvas>
                    </div>
                    */}



            </div>

        </React.Fragment>
    )
}

export default Insights
