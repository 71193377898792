import React, { useEffect, useState, useContext } from 'react'
import './SARenderSummary.css';



const SARenderSummary = ({ service }) => {



    const lines = service?.summarization?.split('\n');
    const wrappedText = lines.map((line, index) => {
        // Trim the line to remove any leading or trailing spaces
        const trimmedLine = line.trim().replace(/\*\*(.*?)\*\*/g, '$1');

        // Check if the line starts and ends with '##'
        if (trimmedLine.startsWith('# Post Mortem')) {
            const content = trimmedLine.slice(1);

            // Wrap the content in a <div> tag
            return <div className='sa-analyzer-incident-summary-post-morten-line' key={index}>{content}</div>;
        }
        if (trimmedLine.startsWith('##')) {
            // Remove '##' from the start and end of the line
            const content = trimmedLine.slice(2);

            // Wrap the content in a <div> tag
            return <div className='sa-analyzer-incident-summary-title-line' key={index}>{content}</div>;
        }

        // If the line doesn't start and end with '##', return it as is
        return <div key={index}>{trimmedLine}</div>;
    });

    return (
        <div>{wrappedText}</div>

    );

}

export default SARenderSummary