import * as React from 'react';
import TextField from '@mui/material/TextField';



const SCInputText = ({ name,label, value, text, className, size, disabled = false,
    handleInputValueChange, handleInputValueBlur, id, refName, required = false, type = 'text',
    readOnly = false, regex = '' , invalid = false}) => {

    return (
        <>
             <div style={{ display: refName ? 'inline-flex' : 'none' }}>                            

                <TextField
             
                    id={id}
                    label={label}                   
                    helperText={text}
                    className={className}
                    type={type}
                    size={size}                   
                    onChange={handleInputValueChange}
                    onBlur={handleInputValueBlur} 
                    required={required}
                    error={invalid}
                    inputProps={
                        { readOnly: readOnly , pattern: regex}
                    }
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled}
                    inputRef={refName}
               
                  
                />

            </div>
            <div style={{ display: !refName ? 'inline-flex' : 'none' }}>
               
            <TextField
                   
                    id={id}
                    label={label}
                    value={value}
                    helperText={text}
                    className={className}
                    type={type}
                    size={size}
                    name={name}
                    onChange={handleInputValueChange}
                    onBlur={handleInputValueBlur}
                    required={required}
                    error={invalid}
                    inputProps={
                        { readOnly: readOnly , pattern: regex }
                    }
                    disabled={disabled}
                    InputLabelProps={{ shrink: true }}
                    

                />
            </div>
        </>
    )
}

export default SCInputText
