import React, { useEffect, useState, useContext, useRef } from 'react'
import './ServiceAnalyzerTimeline.css';
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import * as consts from '../../consts';
import _ from "lodash";
import ServiceAnalyzerTimelineChart from './ServiceAnalyzerTimelineChart';


const ServiceAnalyzerTimeline = ({ serviceId, reportForService }) => {

    const { servicesCached } = useContext(DataContext);
    const { LuxonDateFormatString, GetServiceNameById, translateAnalyzerCodes } = useContext(DataContextAnalyzer);
    const [timelineData, setTimelineData] = useState([])



    useEffect(() => {
        let timelines = [];

     
        if (reportForService && reportForService.length > 0) {

            let clonedReportForService = [...reportForService];
            let parents = clonedReportForService.filter(x => x.parentId === null);

            console.log("parents",parents)
            parents.map((p) => {
                let childParent = clonedReportForService.filter(x => x.id === p.id);
                let parentInsightsAfterFilter = [];
                let childInsightsAfterFilter = [];
                if (childParent.length > 0) {
                    console.log("childParent",childParent)
                    childParent.map((g) => {

                        if (g.report !== null) {
                            parentInsightsAfterFilter.push(filterInsights(g.id, g.report));
                        }

                        let groupOfChild = clonedReportForService.filter(x => x.parentId === p.id);

                        let cIds = [];
                        if (groupOfChild.length > 0) {

                            groupOfChild.map((c) => {

                                cIds.push(c.id)
                                if (c.report !== null) {
                                    childInsightsAfterFilter.push(filterInsights(c.id, c.report));
                                }

                            })
                        }
                        timelines.push({ parentId: p.id, parent: parentInsightsAfterFilter.flatMap(x => x), childIds: cIds, child: childInsightsAfterFilter.flatMap(x => x) });
                    })
                }
            })

            if(timelines && timelines.length > 0){
                let entity = timelines.find(x => x.parentId === serviceId)

                let exists = timelines.map(obj => {
                    const matchingItem = obj.childIds.find(id => id === serviceId);
                    return matchingItem ? obj : null;
                }).filter(Boolean);
    
                let items = null
                if (entity) {
                    if (entity.parent !== null && entity.child !== null) {
                        items = combine(entity)
                    }
    
    
                }
                else {
                    if (exists[0].parent !== null && exists[0].child !== null) {
                        items = combine(exists[0])
                    }
    
    
                }
                setTimelineData([...items]);
    
            }

            

        }

    }, [serviceId])


    const combine = (t) => {
        const combinedReports = t.parent && t.parent.concat(t.child);
        const orderedReports = combinedReports.sort((a, b) => new Date(a.occurredAt) - new Date(b.occurredAt));
        return orderedReports;

    }


    const filterInsights = (id, insights) => {


        const allowedModules = ['deployments', 'configuration', 'database', 'metrics', 'resourcechanges'];
        const preventCodes = ['infra_changes'];
        let cloneInsights = [...insights];
        const includedItems = _.filter(cloneInsights, item => _.includes(allowedModules, item.module.toLowerCase()));

        const excludedCodes = _.reject(includedItems, item => _.includes(preventCodes, item.code.toLowerCase()));
        const sortedItems = _.orderBy(excludedCodes, item => new Date(item.occurredAt));

        const enrichedData = _.map(sortedItems, obj => ({
            ...obj,
            metaData: translateAnalyzerCodes(obj),
            serviceName: GetServiceNameById(servicesCached, id),
            serviceId: id
        }));


        return enrichedData;
    }



    return (
        <React.Fragment>
            <ServiceAnalyzerTimelineChart timeline={timelineData} />
        </React.Fragment>
    )
}

export default ServiceAnalyzerTimeline