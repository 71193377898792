import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useEffect } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import './SCDropDownList.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SCDropDownList = ({ label, componentState, setComponentState, className, size, items, withDeleteOption = true,
   inputId, handleOpen, handleChange, wraperClassName, id, disabled = false, multiple = false, helperText = null }) => {

    const handleClearClick = () => {
        // Handle the logic for clearing the selected value
        //setComponentState();

        if (Array.isArray(componentState)) {
            setComponentState([])
        } else if (componentState instanceof Object) {
            setComponentState({})
        } else if (componentState instanceof Number) {
            setComponentState()
        } else {
            setComponentState('')
        }


    };


    



    return (
        <>
            <div style={{ width: '100%', marginTop: '10px' }} className={wraperClassName} >


                <FormControl sx={{ minWidth: 120 }} size={size} id={id} >
                    <InputLabel shrink id="demo-select-small" >{label}</InputLabel>
                    {
                        withDeleteOption ?
                            (
                                <Select
                                    onOpen={handleOpen}
                                    style={{ textAlign: 'left', maxHeight: '' }}
                                    className={className}
                                    multiple={multiple}
                                    value={componentState}
                                    label={label}
                                    onChange={handleChange}

                                    
                                    endAdornment={
                                        (componentState && componentState?.length > 0) ? (

                                          
                                                <ClearIcon onClick={handleClearClick} className='sc-dropdown-delete-button' fontSize="small"  />
                                           
                                        ) : (
                                            <React.Fragment></React.Fragment>
                                        )

                                    }

                                    disabled={disabled}

                                    inputProps={{
                                        id: inputId,
                                    }}
                                    input={<OutlinedInput id="select-multiple-chip" label={label} notched />}

                                >
                                    {
                                        items && items.map((item, index) => (

                                            item?.disable ?
                                                (
                                                    <MenuItem disabled id={index} key={index} value={item.value} name={item.name} >
                                                        {item.name}
                                                    </MenuItem>
                                                ) :
                                                (
                                                    <MenuItem id={index} key={index} value={item.value} name={item.name} >
                                                        {item.name}
                                                    </MenuItem>
                                                )


                                        ))
                                    }

                                </Select>
                            ) :
                            (
                                <Select
                                    onOpen={handleOpen}
                                    style={{ textAlign: 'left', maxHeight: '' }}
                                    className={className}
                                    multiple={multiple}
                                    value={componentState}
                                    label={label}
                                    onChange={handleChange}
                                    disabled={disabled}

                                    inputProps={{
                                        id: inputId,
                                    }}
                                    input={<OutlinedInput id="select-multiple-chip" label={label} notched />}

                                >
                                    {
                                        items && items.map((item, index) => (

                                            item?.disable ?
                                                (
                                                    <MenuItem disabled id={index} key={index} value={item.value} name={item.name} >
                                                        {item.name}
                                                    </MenuItem>
                                                ) :
                                                (
                                                    <MenuItem id={index} key={index} value={item.value} name={item.name} >
                                                        {item.name}
                                                    </MenuItem>
                                                )


                                        ))
                                    }

                                </Select>
                            )
                    }

                    {
                        helperText !== null ?
                            (
                                <FormHelperText>{helperText}</FormHelperText>

                            ) :
                            (
                                <></>
                            )
                    }
                </FormControl>
            </div>
        </>
    )
}

export default SCDropDownList
