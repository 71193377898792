
export const API_KEY = "59ef52ab-fabf-45e3-b782-acd0735ec234";
export const AZURE_BASE_RESOURCE_URL= "https://portal.azure.com/#@10bis.co.il/resource";
export const requests = {
    fetchServices: '/api/v1/services',
    saveMetaData: '/api/v1/services/{serviceId}/metadata',
    getProjectByServiceAndBranch: '/api/v1/services/{serviceId}/serviceInfo/{branchName}/projects',
    getBuildDefinitions :'/api/v1/services/{serviceId}/serviceInfo/{branchName}/buildDefinitions',
    getServicesWithInfraNugets:'/api/v1/services/graph/{packageName}',
    getPackagesWithVersions: '/api/v1/services/packagesWithVersions',
    /*getServicesByPackageAndVersion: 'api/v1/services/Services/{packageName}/{packageVersion}',
    getServicesByPackageWithoutVersion: 'api/v1/services/Services/{packageName}',*/

    getServicesByPackageAndVersion: 'api/v1/services/Services/byPackage?packageName={packageNameValue}&packageVersion={packageVersionValue}',
    getServicesByPackageWithoutVersion: 'api/v1/services/Services/byPackage?packageName={packageNameValue}',
    
    createGroupNameAndDesc: '/api/v1/groups',
    getGroups:'/api/v1/groups',
    updateServiceToGroup: '/api/v1/groups/{groupId}/assign',
    deleteGroup:'/api/v1/groups/{groupId}',
    getRules:'/api/v1/Rules',
    getInsights:'/api/v1/services/Statistics',
    getApmData:'/api/v1/services?ApmServiceId=',
    getTags:'/api/v1/services/tags',

    freeTextSearch: '/api/v1/services/search?Term={query}&IsExcluded={isExcluded}',
    getTelAvivUsers:'/api/v1/Users/autocomplete?key={query}',

    createUser:'/api/v1/Users',
    getDepartment:'/api/v1/Departments',
    postDepartment:'/api/v1/Departments',
    postTeam:'/api/v1/Teams',
    getTeam:'/api/v1/Teams',
    getAdminUsers:'/api/v1/Users?isManuallyAdded=true',
    updateUser:'/api/v1/Users/{azureUserId}',
    deleteUser:'/api/v1/Users/{azureUserId}',
    updateDepartment:'/api/v1/Departments/{departmentId}',
    removeDepartment:'/api/v1/Departments/{departmentId}',
    getRoles:'/api/v1/Metadata/userRoles',
    updateTeam:'/api/v1/Teams/{teamId}',
    deleteTeam:'/api/v1/Teams/{teamId}',

    getSub:'/api/v1/subscriptions',
    getResourceTypes:'/api/v1/resourcetypes?subscriptionId={subId}',
    getResource:'/api/v1/resources?subscriptionId={subId}&resourceType={resourceType}',

    getResourceRoles:'/api/v1/resource?resourceId={resourceId}',
    createSchedule:'/api/v1/requests',
    deleteRequest:'/api/v1/requests?requestId={requestId}',
    
    getSchedules:'/api/v1/schedules?status=Scheduled',
    getSchedulesExpired:'/api/v1/schedules?status=Expired',
    getJobs:'/api/v1/jobs',
    getMyRequests:'/api/v1/requests',

    createTeamMember:'/api/v1/Users/{userId}/AssignTeam/{teamId}',
    deleteMemberFromTeam:'/api/v1/Users/{userId}/team',

    getServiceNewRelicError:'/api/v1/insights/{newRelicName}/errors',
    getOpenAIResults: '/api/v1/insights/services/{serviceId}/solutions',

    proxy:'/api/v1/proxy/GetJsonFile',

    getCICDAutoProjects:'/api/v1/Devops/Projects',    
    getCICDAutoACR:'/api/v1/Devops/ACRs?projectName={projectName}',
    getCICDAutoFeed:'/api/v1/Devops/Artifacts?projectName={projectName}',
    createCICDAuto:'/api/v1/Devops/InitDevOpsFlow',
    getCICDAutoRequests:'/api/v1/UserRequest/UserRequests',
    updateUserReadNotifications:'/api/v1/UserRequest/UpdateUserRequests',
    getCICDDatabaseNames:'api/v1/DataBase/DataBases',
    
    updateExcludeInBackstage:'/api/v1/services/{serviceId}/bs/exclude',
    updateIncludeInBackstage:'/api/v1/services/{serviceId}/bs/unexclude',

    getBuildPipelinesStructure:'/api/v1/PipelinesDefinition/BuildPipelinesStructure?projectName={projectName}',
    getSelfServiceRepositories:'/api/v1/Devops/Repositories?projectName={projectName}',
    getSelfServiceBranchesForExsitingRepo:'/api/v1/Devops/Branches?projectName={projectName}&repositoryName={repoName}',
    getSelfServiceSubscriptions:'/api/v1/Devops/Subscriptions',
    getKVNames:'/api/v1/Devops/KeyVaults?subscriptionId={subscriptionId}',
    getSecrets:'/api/v1/Devops/Secretes?keyVaultUri={keyVaultUri}',

    //Analyzer
    getMicrosoftServiceHealth:'/api/v1/Health/HealthOverview?SubscriptionId={subscriptionId}',
    getDeploymentChanges:'/api/v1/Deployment?project={project}&releasesDefinitionId={releaseDefId}&limit={limit}&from={fromDate}&to={toDate}',
    getConfigurationChanges:'/api/v1/Changes?AppName={appName}&AppServiceResourceId={appServiceResourceId}&FromDate={fromDate}&ToDate={toDate}',
    getResourcesAKSMetrics:'/api/v1/Matrics/ResourceClusterMatrics?SubscriptionId={subscriptionId}&ClusterName={clusterName}&ResourceGroup={resourceGroupName}&FromDate={fromDate}&ToDate={toDate}',
    getLogsCount:'/api/v1/LogsCount?AppName={appName}&FromDate={fromDate}&ToDate={toDate}',
    getLogsDetails:'/api/v1/Logs?AppName={appName}&FromDate={fromDate}&ToDate={toDate}',
    getAppResourcesMetrics:'/api/v1/Matrics/ResourceAppServiceMatrics?SubscriptionId={subscriptionId}&ResourceGroupName={resourceGroupName}&ResourceName={resourceName}'+
    '&resourceProviderNamespace={resourceProviderNamespace}&FromDate={fromDate}&ToDate={toDate}',
    getScanHistory:'/api/v1/ServicesReportsHistory?{params}',
    getRootCauseAnalysis:'/api/v1/RootCauseAnalysis?reportId={reportId}',
    

    getDiagnosticDataForAppService:'/api/v1/Diagnostics/DiagnosticDataForAppService/subscriptionId/{subscriptionId}/resourceGroup/{resourceGroup}/service/{serviceName}?startTime={fromDate}&endTime={toDate}',
    getDiagnosticDataForAKS: '/api/v1/Diagnostics/DiagnosticDataForAksCluster/subscriptionId/{subscriptionId}/resourceGroup/{resourceGroup}/ClusterName/{clusterName}?startTime={fromDate}&endTime={toDate}',
    getDBChanges:'/api/v1/Alerts/Grouped?start={fromDate}',
    getAppDependencies:'/api/v1/AppDependencies?appName={appName}',
    getDBAlerts:'/api/v1/Alerts?start={fromDate}&end={toDate}&appName={appName}',

    //microsoft health
    getMicrosoftHealth:'/api/v1/Health/HealthOverview?SubscriptionId={subscriptionId}',


    //new analyzer
    scan:'/api/v1/Scan',
    incident:'/api/v1/Incidents',
    report:'/api/v1/Report?RequestId={requestId}&ServiceId={serviceId}',
    status:'/api/v1/Status?RequestId={requestId}',
    createGroup:'/api/v1/AnalyzerServiceGroups',
    getApplicationGroups:'/api/v1/AnalyzerServiceGroups',
    deleteApplicationGroups:'/api/v1/AnalyzerServiceGroups/{groupId}',
    updateGroup:'/api/v1/AnalyzerServiceGroups/{groupId}',
    getCollectedData:'/api/v1/CollectedData?RequestId={requestId}&ServiceId={serviceId}',
    predict:'/api/v1/Predict',
    reportCommentPost:'/api/v1/ReportComments',
    reportCommentGet:'api/v1/ReportComments?RequestId={requestId}&ServiceId={serviceId}',

    //Counters 
    getPODS: '/api/Aks/pods/{namespace}?api-version=1',
    postCounters: '/api/Counters/AddNewRequest?api-version=1',
    getCounters:'/api/Counters/{requestId}?api-version=1',


    //Dump
    postDump:'/api/MemoryDump/CreateForAks/namespace/{namespace}?api-version=1',
    getDump:'/api/MemoryDump/{requestId}?api-version=1',


    //Console AI
    getTopReviewsRestaurants:'/api/v1/TopReviewsRestaurants?numOfResults={numOfResults}',
    generateReviewsCategories: '/api/v1/ClassifyReviews?RestaurantId={RestaurantId}&FromDate={FromDate}&ToDate={ToDate}',
    generateRestaurantSummary: '/api/v1/GenerateRestaurantSummary?RestaurantId={RestaurantId}&FromDate={FromDate}&ToDate={ToDate}'


   

};
export const INFRA_PKG_ID = '628b3fe19d22af7a3dd3f139';
export const INFRA_PKG_NAME = 'infrastructure';
export const EDITOR_ROLE = 'ServiceCatalog.Edit';
export const DESC_DEFAULT = 'What a great service! but still the description is missing :(';


export const INFRA_CHANGES = "infra_changes"
export const HEALTH_CHANGES = "impact"
export const INFRA_CHANGES_EXCLUDE = "data_team_rg"


export const DIAGNOSTICS_SCALING_OPERATIONS = "diagnostics_scaling_operations"
export const DIAGNOSTICS_ALERT = "diagnostics_alert"
export const DIAGNOSTICS_CPU_DRILL_DOWN = "diagnostics_cpu_drill_down"
export const DIAGNOSTICS_EVENTS_LOGS = "diagnostics_event_logs"
export const DIAGNOSTICS_MEMORY_DRILL_DOWN = "diagnostics_memory_drill_down"
export const DIAGNOSTICS_KV_APP_SETTINGS = "diagnostics_key_vault_app_settings"
export const DIAGNOSTICS_SNAT_PORT_EXHAUSTION = "diagnostics_snat_port_exhaustion"
export const DIAGNOSTICS_SWAP = "diagnostics_swap_availability_and_performance"
export const DIAGNOSTICS_TCP = "diagnostics_tcp_connections_diagnostic"
export const DIAGNOSTICS_SSL = "diagnostics_ssl_and_domains"
export const DIAGNOSTICS_RESTART = "diagnostics_restart"
export const DIAGNOSTICS_AKS="aks_diagnostics"