import React, { useEffect, useContext } from 'react'
import DataContext from "../Context/DataContext";
import './PipelineTreeModal.css';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import FolderIcon from '@mui/icons-material/Folder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import * as consts from '../consts';
import cicdAxios from '../cicdAxios';
import _ from "lodash";
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SCButton from '../Components/SCButton';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import useAxios from '../Hooks/useAxios';


const PipelineTreeModal = ({ show, setShowPipelineTree, setSelectedValue, selectedValue, title, projectName }) => {


    const instance = useAxios(process.env.REACT_APP_CICD_BASE_URL);

    const { modalShow, setModalShow, token } =
        useContext(DataContext);
    const [open, setOpen] = React.useState(true);
    const [tree, setTree] = React.useState([]);
    const [rootFolder, setRootFolder] = React.useState();
    const [parentChildTree, setParentChildTree] = React.useState([]);


    useEffect(()=>{ setTree([])},[])

    useEffect(async () => {
        setTree([])
        if (!show) {
            return;
        }
        let result = await getCICDAutoProjects();
        let items = [];

        for (const key in result.data.content) {
            if (result.data.content.hasOwnProperty(key)) {
                const value = result.data.content[key];

                let item = { key: formatFolderName(key), folderID: value.folderID, parentFolderID: value.parentFolderID, relativePath:value.relativePath, path: value.path, pipelines: value.pipelines, childFolders: [], open: false };
                if (item.path === '\\') {
                    setRootFolder(item)
                }
                items.push(item)
            }
        }
        setTree(items)

    }, [show])

    useEffect(() => {

        let parents = tree?.filter(t => t.parentFolderID === rootFolder.folderID);
        if (tree.length === 0) {
            return;
        }

        parents?.map((p) => {
            getFolderChild(p)
        })
        setParentChildTree(parents)

    }, [tree])

    const formatFolderName = (inputString) => {
        if (inputString.charAt(0) === '\\') {
            return inputString.substring(1);
        }
        return inputString;
    }

    const getFolderChild = (p) => {

        let childArray = tree?.filter((t) => t.parentFolderID === p.folderID && t.folderID !== p.folderID)

        p.childFolders = childArray;
        p.childFolders.map((cf) => {
            getFolderChild(cf)
        })

    }




    const getCICDAutoProjects = async () => {

        try {
            return await instance.get(consts.requests.getBuildPipelinesStructure.replace('{projectName}', projectName));

        }
        catch (error) {

        }

    }


    
    const handleClick = (arrayItem) => {

        arrayItem.open = !arrayItem.open;
        if (arrayItem.open) {
            //setActiveItem(arrayItem.key)
            //setSelectedValue(arrayItem.relativePath)
            setSelectedValue(arrayItem.path)
        }

        const updatedItem = arrayItem;

        const updatedArray = _.map(parentChildTree, (item) => {
            if (item.folderID === arrayItem.folderID) {
                return updatedItem; // Replace the item with the updated one
            }
            return item; // Return other items as-is
        });
        setParentChildTree(updatedArray)
    };

    const handlePipelineTreehModalClose = () => {
        setSelectedValue("")
        setShowPipelineTree(false)
    }

    const handlePipelineTreehModalOk = () => {
        
        setShowPipelineTree(false)
    }

    const PipelineList = ({ items ,root = false }) => {
        
        if(!root){
            return (
                <div>
                    {
                        items.map((p,index) => (
                            <div id={`pipeline-name-${index}`} className='ptm-pipeline-name' key={`pipeline-key-${index}`}><RocketLaunchIcon className='pipeline-tree-rocket'/>{p.name}</div>
                        ))
                    }
                </div>
            )
        }
        if(root){
            if (items.length > 0) {
                let rootPipeline = items.filter(p => p.path === "\\");
                if (rootPipeline.length > 0) {
    
                    if (rootPipeline[0].pipelines.length > 0) {
    
                        return (
                            <div>
                                {
                                    rootPipeline[0].pipelines.map((p) => (
                                        <div id={`pipeline-${p.name}`} className='ptm-pipeline-name' key={p.name}><RocketLaunchIcon className='pipeline-tree-rocket'/>{p.name}</div>
                                    ))
                                }
                            </div>
                        )
    
                    }
    
                }
                return (
                    <React.Fragment>
                    </React.Fragment>
                )
            }
    
            return (
                <React.Fragment></React.Fragment>
            )
        }
        

    }

    const RecursiveList = ({ items }) => {
        return (
            <List>
                {items.map((item,index) => (
                    item.path !== "\\" ?
                        (
                            <div id={`path-${item.key}-${index}`} key={item.relativePath}  >
                                <ListItem onClick={() => handleClick(item)} key={`list-item-` + item.relativePath}>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <Typography variant="inherit" style={{ fontWeight: selectedValue === item.path ? 'bold' : 'normal' }}>
                                        {item.relativePath}
                                    </Typography>
                                    {item.childFolders.length > 0 ? (
                                        item.open ? (
                                            <ExpandMoreIcon />
                                        ) : (
                                            <ChevronRightIcon />
                                        )
                                    ) : null}
                                </ListItem>
                                <Collapse in={item.open} timeout="auto" key={`collepse-` + item.relativePath}   >
                                    <div className='ptm-collapse-items-container' key={`item-container-` + item.relativePath}>
                                        {
                                            item.pipelines.length > 0 && (
                                                <PipelineList items={item.pipelines} />
                                            )}
                                        {
                                            item.childFolders.length > 0 && (
                                                <RecursiveList items={item.childFolders} />
                                            )}
                                    </div>
                                </Collapse>
                            </div>
                        ) : (
                            <React.Fragment>
                            </React.Fragment>
                        )

                ))}
            </List>
        )
    }

    return (

        <div>

            <Modal

                fullscreen={false}
                show={show}
                onHide={() => handlePipelineTreehModalClose()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ maxHeight: window.innerHeight * 0.9 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pipeline-tree-modal-container">

                    <div>
                        <RecursiveList items={parentChildTree} />
                    </div>
                    <div>
                        <PipelineList items={parentChildTree} root={true} />
                    </div>

                </Modal.Body>
                <Modal.Footer>                    
                    <Button variant="outline-success" onClick={() => handlePipelineTreehModalClose()}>Close</Button>
                    <SCButton customClass="btn-outline-remove-ok-option" size="medium" text="OK" handleButtonClick={() => handlePipelineTreehModalOk()} />                    
                </Modal.Footer>
            </Modal>

        </div>

    )
}

export default PipelineTreeModal
