import { useEffect } from 'react';
import './SCCheckBox.css';

const SCCheckBox = ({customClass, handleClick}) => {
    
   

    return (
       <div  className={customClass}  onClick={handleClick} />                 
    )
}

export default SCCheckBox
