import { createContext, useState, useContext } from 'react';
import useLogin from '../Hooks/useLogin';
import * as consts from '../consts';
import useAxios from '../Hooks/useAxios';
import DataContext from './DataContext';




const DataContextConsoleAI = createContext({});

export const DataProviderConsoleAI = ({ children }) => {

    const {  token } = useContext(DataContext);

    const instanceConsoleAICustomerReview = useAxios(process.env.REACT_APP_CUSTOMER_REVIEW_BASE_URL);

    const getResturantsDetails = async (numOfResults) => {

        if (token) {
            const url = consts.requests.getTopReviewsRestaurants.replace('{numOfResults}', numOfResults);
            return await instanceConsoleAICustomerReview.get(url)
        }
    }

    const generateReviewsCategories = async (resturantId, fromDate, toDate) =>{
        if (token) {
            const url = consts.requests.generateReviewsCategories.replace('{RestaurantId}', resturantId).replace('{FromDate}', fromDate).replace('{ToDate}', toDate);
            return await instanceConsoleAICustomerReview.get(url)
        }
    }

    const generateRestaurantSummary = async (resturantId, fromDate, toDate) =>{
        if (token) {
            const url = consts.requests.generateRestaurantSummary.replace('{RestaurantId}', resturantId).replace('{FromDate}', fromDate).replace('{ToDate}', toDate);
            return await instanceConsoleAICustomerReview.get(url)
        }
    }

    return (
        <DataContextConsoleAI.Provider value={{
            getResturantsDetails, generateReviewsCategories,generateRestaurantSummary
        }}>



            {children}
        </DataContextConsoleAI.Provider>
    )

}

export default DataContextConsoleAI;
